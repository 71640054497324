import { createContext, useState } from 'react';

export const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [modes, setModes] = useState([]);
    const [flag, setFlag] = useState(false);
    const [user, setUser] = useState({
        name: '',
        email: '',
        amount: 0,
        lang: '',
        model: '',
        subscription: {},
        avatar: '/public/avatar/avatar.jpg',
        admin: false
    });

    return (
        <AppContext.Provider
            value={{
                modes,
                setModes,
                flag,
                setFlag,
                user,
                setUser
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider;