import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalContent,
    Center,
    Flex,
    Box,
    Heading,
    Text,
    IconButton,
    Button,
    VStack,
    HStack,
    Wrap,
    WrapItem,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    useToast
} from '@chakra-ui/react';

import {
    MdPhone,
    MdEmail,
    MdLocationOn,
    MdFacebook,
    MdOutlineEmail,
  } from 'react-icons/md'
import { BsGithub, BsDiscord, BsPerson } from 'react-icons/bs'
import { useState } from 'react';
import { mail } from '../util/constant';
import { useTranslation } from 'react-i18next';

export const Contact = ({isOpen, onClose}) => {
    const { t } = useTranslation("common");
    const toast = useToast();
    const [data, setData] = useState({
        sub: '',
        email: '',
        msg: ''
    });

    const emailRegex = new RegExp(
		/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
		"gm"
	);

    const handleSendMessage = () => {
        if (data.email == '') {
            return toast({
                title: 'Error',
                status: 'error',
                description: 'Mail is required.',
                duration: '3000',
                isClosable: true,
                position: 'top-right'
            })
        }
        if (data.sub == '') {
            return toast({
                title: 'Error',
                status: 'error',
                description: 'Subject is required.',
                duration: '3000',
                isClosable: true,
                position: 'top-right'
            })
        }
        if (data.msg == '') {
            return toast({
                title: 'Error',
                status: 'error',
                description: 'Message is required.',
                duration: '3000',
                isClosable: true,
                position: 'top-right'
            })
        }

        if (emailRegex.test(data.email) == false) {
            return toast({
                title: 'Error',
                status: 'error',
                description: 'Mail is invalid.',
                duration: '3000',
                isClosable: true,
                position: 'top-right'
            })
        }
        onClose();
        const mailtoLink = `mailto:${mail}?subject=${encodeURIComponent(data.sub)}&body=${encodeURIComponent("Email: " + data.email + " " + data.msg)}`;
        let element = document.createElement('a');
        element.setAttribute('href', mailtoLink);
        element.click();
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={'4xl'}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('contact.title')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Center>
                        <Flex>
                            <Box display={'flex'} p={4}>
                                <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
                                    <WrapItem>
                                        <Box>
                                        <Heading>{t('contact.title')}</Heading>
                                        <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
                                            {t('contact.desc')}
                                        </Text>
                                        <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                                            <VStack pl={0} spacing={3} alignItems="flex-start" textAlign={'left'}>
                                                <Button
                                                    size="md"
                                                    height="48px"
                                                    width="280px"
                                                    variant="ghost"
                                                    color="#DCE2FF"
                                                    _hover={{ border: '2px solid #1C6FEB' }}
                                                    leftIcon={<MdEmail color="#1970F1" size="20px" />}>
                                                    itslenson@mindaiproject.com
                                                </Button>
                                                <Button
                                                    size="md"
                                                    height="48px"
                                                    width="280px"
                                                    variant="ghost"
                                                    color="#DCE2FF"
                                                    _hover={{ border: '2px solid #1C6FEB' }}
                                                    leftIcon={<MdLocationOn color="#1970F1" size="20px" />}>
                                                    https://t.me/itsB3nson 
                                                </Button>
                                            </VStack>
                                        </Box>
                                        </Box>
                                    </WrapItem>
                                    <WrapItem>
                                        <Box borderRadius="lg">
                                        <Box m={8} color="white">
                                            <VStack spacing={5}>
                                            <FormControl id="name">
                                                <FormLabel>{t('contact.main')}</FormLabel>
                                                <InputGroup borderColor="#585858">
                                                <InputLeftElement pointerEvents="none">
                                                    <MdOutlineEmail color="gray.800" />
                                                </InputLeftElement>
                                                <Input type="text" size="md" value={data.email} placeholder={t('contact.main')} onChange={e => setData({...data, email: e.target.value})}/>
                                                </InputGroup>
                                            </FormControl>
                                            <FormControl id="name">
                                                <FormLabel>{t('contact.subject')}</FormLabel>
                                                <InputGroup borderColor="#585858">
                                                <InputLeftElement pointerEvents="none">
                                                    <BsPerson color="gray.800" />
                                                </InputLeftElement>
                                                <Input type="text" size="md" placeholder={t('contact.subject')} value={data.sub} onChange={e => setData({...data, sub: e.target.value})}/>
                                                </InputGroup>
                                            </FormControl>
                                            <FormControl id="name">
                                                <FormLabel>{t('contact.message')}</FormLabel>
                                                <Textarea
                                                    borderColor="#585858"
                                                    _hover={{
                                                        borderRadius: 'gray.300',
                                                    }}
                                                    placeholder={t('contact.message')}
                                                    value={data.msg}
                                                    onChange={e => setData({...data, msg: e.target.value})}
                                                />
                                            </FormControl>
                                            <FormControl id="name" float="right">
                                                <Button variant="solid" bg="#0D74FF" color="white" _hover={{}} onClick={handleSendMessage}>
                                                    {t('contact.send')}
                                                </Button>
                                            </FormControl>
                                            </VStack>
                                        </Box>
                                        </Box>
                                    </WrapItem>
                                </Wrap>
                            </Box>
                        </Flex>
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}