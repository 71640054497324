import React from 'react'
import {
    Tag,
    VStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Heading,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightAddon,
    Box
} from "@chakra-ui/react"
import { Search2Icon } from "@chakra-ui/icons";

export default function History() {
    return (
        <div className='Container'>
            <VStack w={'100%'} padding={5}>
                <Heading color={'white'} py={5}>Payment History</Heading>
                <Box w={'100%'} justifyContent={'end'} display={'flex'}>
                    <InputGroup w={'25%'} borderRadius={5} size="sm">
                        <InputLeftElement
                            pointerEvents="none"
                            children={<Search2Icon color="gray.600" />}
                        />
                        <Input type="text" placeholder="Search..." border="1px solid #949494" />
                        <InputRightAddon
                            p={0}
                            border="none"
                        >
                        <Button size="sm" borderLeftRadius={0} borderRightRadius={3.3} border="1px solid #949494">
                            Search
                        </Button>
                        </InputRightAddon>
                    </InputGroup>
                </Box>
                <Table>
                    <Thead>
                        <Tr>
                            <Th className='cell'>OrderId</Th>
                            <Th className='cell'>Status</Th>
                            <Th className='cell' isNumeric>Amount</Th>
                            <Th className='cell'>Network</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td className='cell'>7a4af3f5se4s2d1se4ddwaa421a4a1</Td>
                            <Td className='cell'>
                                <Tag colorScheme='green' variant={'outline'}>Paid</Tag>
                            </Td>
                            <Td className='cell' isNumeric>6</Td>
                            <Td className='cell'>btc</Td>
                        </Tr>
                        <Tr>
                            <Td className='cell'>7a4af3f5se4s2d1se4ddwaa421a4a1</Td>
                            <Td className='cell'>
                                <Tag colorScheme='orange' variant={'outline'}>Wrong Amount</Tag>
                            </Td>
                            <Td className='cell' isNumeric>6</Td>
                            <Td className='cell'>btc</Td>
                        </Tr>
                        <Tr>
                            <Td className='cell'>7a4af3f5se4s2d1se4ddwaa421a4a1</Td>
                            <Td className='cell'>
                                <Tag colorScheme='red' variant={'outline'}>Fail</Tag>
                            </Td>
                            <Td className='cell' isNumeric>6</Td>
                            <Td className='cell'>btc</Td>
                        </Tr>
                        <Tr>
                            <Td className='cell'>7a4af3f5se4s2d1se4ddwaa421a4a1</Td>
                            <Td className='cell'>
                                <Tag colorScheme='red' variant={'outline'}>Fail</Tag>
                            </Td>
                            <Td className='cell' isNumeric>6</Td>
                            <Td className='cell'>btc</Td>
                        </Tr><Tr>
                            <Td className='cell'>7a4af3f5se4s2d1se4ddwaa421a4a1</Td>
                            <Td className='cell'>
                                <Tag colorScheme='red' variant={'outline'}>Fail</Tag>
                            </Td>
                            <Td className='cell' isNumeric>6</Td>
                            <Td className='cell'>btc</Td>
                        </Tr><Tr>
                            <Td className='cell'>7a4af3f5se4s2d1se4ddwaa421a4a1</Td>
                            <Td className='cell'>
                                <Tag colorScheme='red' variant={'outline'}>Fail</Tag>
                            </Td>
                            <Td className='cell' isNumeric>6</Td>
                            <Td className='cell'>btc</Td>
                        </Tr><Tr>
                            <Td className='cell'>7a4af3f5se4s2d1se4ddwaa421a4a1</Td>
                            <Td className='cell'>
                                <Tag colorScheme='red' variant={'outline'}>Fail</Tag>
                            </Td>
                            <Td className='cell' isNumeric>6</Td>
                            <Td className='cell'>btc</Td>
                        </Tr><Tr>
                            <Td className='cell'>7a4af3f5se4s2d1se4ddwaa421a4a1</Td>
                            <Td className='cell'>
                                <Tag colorScheme='red' variant={'outline'}>Fail</Tag>
                            </Td>
                            <Td className='cell' isNumeric>6</Td>
                            <Td className='cell'>btc</Td>
                        </Tr><Tr>
                            <Td className='cell'>7a4af3f5se4s2d1se4ddwaa421a4a1</Td>
                            <Td className='cell'>
                                <Tag colorScheme='red' variant={'outline'}>Fail</Tag>
                            </Td>
                            <Td className='cell' isNumeric>6</Td>
                            <Td className='cell'>btc</Td>
                        </Tr><Tr>
                            <Td className='cell'>7a4af3f5se4s2d1se4ddwaa421a4a1</Td>
                            <Td className='cell'>
                                <Tag colorScheme='red' variant={'outline'}>Fail</Tag>
                            </Td>
                            <Td className='cell' isNumeric>6</Td>
                            <Td className='cell'>btc</Td>
                        </Tr><Tr>
                            <Td className='cell'>7a4af3f5se4s2d1se4ddwaa421a4a1</Td>
                            <Td className='cell'>
                                <Tag colorScheme='red' variant={'outline'}>Fail</Tag>
                            </Td>
                            <Td className='cell' isNumeric>6</Td>
                            <Td className='cell'>btc</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </VStack>
        </div>
    )
}
