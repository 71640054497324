import React, { useEffect, useState, useContext } from 'react';
import {
    Modal,
    ModalBody,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
    Divider,
    VStack,
    Image,
    Button,
    Card,
    Stack,
    CardBody,
    Heading,
    Text,
    CardFooter,
    Tabs,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Flex,
    Input,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Center,
    WrapItem,
    Wrap,
    Badge,
    Box
} from '@chakra-ui/react';
import {
    useToast,
    useDisclosure
} from '@chakra-ui/react'
import { GoPlus } from "react-icons/go";
import { deleteMineBot, getMineBots } from '../actions/prompts';
import { useCookies } from 'react-cookie'
import {
    chains_list,
    SERVER_URL,
    own_renounced,
    not_own_renounced,
    black_func_renounced,
    not_black_func_renounced,
    can_blacklist,
    not_can_blacklist,
    can_mint,
    not_can_mint,
    can_burn,
    not_can_burn,
    has_trading_cooldown,
    not_has_trading_cooldown,
    honeypot,
    not_honeypot,
    external_contract_risk,
    not_external_contract_risk,
    has_suspicious_functions,
    not_has_suspicious_functions,
    pause_trading,
    not_pause_trading
} from '../util/constant';
import { ChevronDownIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { getAuditRequest } from '../actions/api';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { deleteChatHistory, getChatList } from '../actions/chat_history';
import { AppContext } from '../provider/AppProvider';
import { DeleteAlert } from './DeleteAlert';
import { useTranslation } from 'react-i18next';
import CreateAssistant from './CreateAssistant';

export const Gallery = ({ isOpen, onClose, setMessages }) => {
    const { t } = useTranslation("common");
    const { setModes, setFlag } = useContext(AppContext);
    const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
    const { isOpen: isCreateBotOpen, onOpen: onCreateBotOpen, onClose: onCreateBotClose } = useDisclosure();
    const toast = useToast();
    const [cookies] = useCookies(['token']);
    const [data, setData] = useState([]);
    const [response, setResponse] = useState(null);
    const [chatList, setChatList] = useState([]);
    const [id, setId] = useState('');
    const handleSetData = (values) => {
        setData(values);
    }
    useEffect(() => {
        if (isOpen) {
            getMineBots(cookies.token, handleSetData);
        }        
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        if (!isCreateBotOpen && !isAlertOpen) {
            getMineBots(cookies.token, handleSetData);
        }
    }, [isCreateBotOpen, isAlertOpen]);

    const handleAlertDeleteBot = (id) => {
        onAlertOpen();
        setId(id);
        // deleteMineBot(cookies.token, {id}, handleSetData, setModes);
    }

    const handleDeleteBot = () => {
        setFlag(true);
        deleteMineBot(cookies.token, { id }, handleSetData, setModes, setFlag);
        onAlertClose();
    }

    const [audit, setAudit] = useState({
        chain: '',
        address: '',
        chainName: ''
    });

    const handleSetResponse = val => {
        setResponse(val);
    }

    const handleSendRequest = () => {
        getAuditRequest(audit.chain, audit.address, handleSetResponse, errorMsg);
    }

    const errorMsg = (msg) => {
        toast({
            title: 'Error',
            description: msg,
            duration: '3000',
            isClosable: true,
            position: 'top-right'
        })
    }

    const handleGetChatList = () => {
        getChatList(cookies.token, handleSetChatList);
    }

    const handleSetChatList = (val) => {
        setChatList(val)
    }

    const handleDeleteChatItem = (val) => {
        deleteChatHistory(cookies.token, { id: val });
        setChatList(chatList.filter(item => item._id !== val));
    }

    const handleSelectChat = (val) => {
        const idx = chatList.findIndex(item => item._id == val);
        if (idx < 0) return;

        setMessages({ messages: chatList[idx].messages, mode: chatList[idx].mode, img: chatList[idx].img });
        onClose();
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={'5xl'}
            scrollBehavior='inside'
        >
            <ModalOverlay />
            <ModalContent minH={500}>
                <ModalHeader>{t("interface.title")}</ModalHeader>
                <ModalCloseButton />
                <Divider />
                <ModalBody>
                    <Tabs>
                        <TabList>
                            <Tab>{t("interface.bot")}</Tab>
                            <Tab onClick={handleGetChatList}>{t("interface.history")}</Tab>
                            <Tab>{t("interface.audit.title")}</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel height={500} overflowY={'auto'}>
                                <Wrap spacing={'1rem'}>
                                    <WrapItem>
                                        <Card w={'7rem'} h={'9rem'} borderRadius={20} border={'dashed 2px'} cursor={'pointer'} onClick={onCreateBotOpen}>
                                            <CardBody w={'7rem'} h={'8.5rem'}>
                                                <Center h={'full'}>
                                                    <VStack>
                                                        <GoPlus fontSize={'3rem'} />
                                                        <Text textAlign={'center'}>
                                                            {t('content.create_bot.add')}
                                                        </Text>
                                                    </VStack>
                                                </Center>
                                            </CardBody>
                                        </Card>
                                    </WrapItem>
                                    {
                                        data.map((item, idx) => {
                                            return <WrapItem key={'index_'+idx}>
                                                <Card maxW={'7rem'} borderRadius={20} bgColor={'#8396b9'}>
                                                    <CardBody w={'7rem'} h={'5.9rem'} position={'relative'} padding={2}>
                                                        <Box w={'full'} overflow={'hidden'} borderTopLeftRadius={15} borderTopRightRadius={15}>
                                                            <Image
                                                                w={'full'}
                                                                h={'5.9rem'}
                                                                className='bot_card'
                                                                src={SERVER_URL + item.img}
                                                                alt='Green double couch with wooden legs'
                                                                borderRadius='lg'
                                                                cursor={'pointer'}
                                                            />
                                                        </Box>
                                                        <Badge zIndex={100} bgColor={'#fed7d7'} color={'#822727'} cursor={'pointer'} colorScheme='red' borderRadius={'50%'} justifyContent={'center'} w={'1.2rem'} h={'1.2rem'} position={'absolute'} right={0} top={0} fontSize={'1.2rem'} padding={0} onClick={() => handleAlertDeleteBot(item._id)}>
                                                            <SmallCloseIcon position={'absolute'} left={0} top={0} />
                                                        </Badge>
                                                    </CardBody>
                                                    <Divider />
                                                    <CardFooter justifyContent={'center'} padding={'0.3rem 1rem'}>
                                                        <Box textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'}>
                                                            {item.name}
                                                        </Box>
                                                    </CardFooter>
                                                </Card>
                                            </WrapItem>
                                        })
                                    }
                                </Wrap>
                                {/* <VStack>
                                    {
                                        data.map((item) => (
                                            <Card
                                                key={item._id}
                                                width={'100%'}
                                                direction={{ base: 'column', sm: 'row' }}
                                                overflow='hidden'
                                                variant='outline'
                                            >
                                                <Image
                                                    objectFit='cover'
                                                    maxW={{ base: '100%', sm: '200px' }}
                                                    src={SERVER_URL + item.img}
                                                    alt='Caffe Latte'
                                                />

                                                <Stack>
                                                    <CardBody>
                                                        <Heading size='md'>{item.name}</Heading>

                                                        <Text py='2'>{item.content}</Text>
                                                    </CardBody>

                                                    <CardFooter>
                                                        <Button variant='solid' colorScheme='blue' onClick={() => handleAlertDeleteBot(item._id)}>
                                                            {t("interface.delete")}
                                                        </Button>
                                                    </CardFooter>
                                                </Stack>
                                            </Card>
                                        ))
                                    }
                                </VStack> */}
                            </TabPanel>
                            <TabPanel>
                                {
                                    chatList.map(item => {
                                        return <Card my={2} key={item._id}>
                                            <Flex justifyContent={'space-between'} alignItems={'center'} px={3} py={1} borderRadius={'md'}>
                                                <Text onClick={() => handleSelectChat(item._id)} cursor={'pointer'}>{item.name}</Text>
                                                <Button zIndex={10} onClick={() => handleDeleteChatItem(item._id)}><RiDeleteBin6Line /></Button>
                                            </Flex>
                                        </Card>
                                    })
                                }
                            </TabPanel>
                            <TabPanel>
                                <Flex alignItems={'center'} justifyContent={'space-between'} fontSize={['12px', { md: '16px' }]}>
                                    <Text pr={2}>{t("interface.audit.title")}: </Text>
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} value={audit.chain} fontSize={['12px', { md: '16px' }]} px={[0, { sm: 2 }]}>
                                            {audit.chain === '' ? t("interface.audit.chain") : audit.chainName}
                                        </MenuButton>
                                        <MenuList style={{ maxHeight: "27vh", overflowY: "scroll" }}>
                                            {
                                                chains_list.map(item => {
                                                    return <MenuItem key={item[1]} value={item[1]} onClick={() => setAudit({ ...audit, chain: item[1], chainName: item[0] })}>{t(`interface.audit.chains.${item[1]}`)}</MenuItem>
                                                })
                                            }
                                        </MenuList>
                                    </Menu>
                                    <Input w={'50%'} placeholder='0x0000000000000000000000000000000000000000' value={audit.address} onChange={e => setAudit({ ...audit, address: e.target.value })} />
                                    <Button onClick={handleSendRequest} fontSize={['12px', { md: '16px' }]}>{t("interface.audit.request")}</Button>
                                </Flex>
                                {response !== null && <VStack alignItems={'start'}>
                                    <br />
                                    <br />
                                    <Text fontSize={'2xl'} fontWeight={'bold'}>{response.contract.name} ({response.contract.symbol}) {t('interface.audit.result.title')}</Text>
                                    <br />
                                    <Text fontSize={'xl'} fontWeight={'bold'}>📝 {t('interface.audit.result.overview')}</Text>
                                    <Text>-{t('interface.audit.result.address')}: {response.contract.address}</Text>
                                    <Text>-{t('interface.audit.result.type')}: {response.contract.type}</Text>
                                    <Text>-{t('interface.audit.result.decimal')}: {response.contract.decimals}</Text>
                                    <Text>-{t('interface.audit.result.total')}: {response.contract.totalSupply} {response.contract.symbol}</Text>
                                    <Text>-{t('interface.audit.result.verified')}: {response.contract.contractVerified.toString()}</Text>
                                    <br />
                                    <Text fontSize={'xl'} fontWeight={'bold'}>💸 {t('interface.audit.result.fee')}</Text>
                                    <Text>-{t('interface.audit.result.buy_fee')}: {response.fees.buyFee}</Text>
                                    <Text>-{t('interface.audit.result.sell_fee')}: {response.fees.sellFee}</Text>
                                    <Text>-{t('interface.audit.result.tax')}: {response.fees.transferTax}</Text>
                                    <br />
                                    <Text fontSize={'xl'} fontWeight={'bold'}>🔐 {t('interface.audit.result.owner')}</Text>
                                    <Text>-{t('interface.audit.result.owner_address')}: {response.ownershipvscontrol.ownerAddress == null ? 'None' : response.ownershipvscontrol.ownerAddress}</Text>
                                    <Text>-{t('interface.audit.result.status')}: {response.ownershipvscontrol.ownershipStatus ? own_renounced : not_own_renounced}</Text>
                                    <Text>-{t('interface.audit.result.blacklist')}: {response.ownershipvscontrol.canBlacklist ? can_blacklist : not_can_blacklist}</Text>
                                    <Text>-{t('interface.audit.result.renonunced')}: {response.ownershipvscontrol.blacklist == null ? not_black_func_renounced : black_func_renounced}</Text>
                                    <br />
                                    <Text fontSize={'xl'} fontWeight={'bold'}>🛠 {t('interface.audit.result.functionality')}</Text>
                                    <Text>-{t('interface.audit.result.mint')}: {response.contractFunctionality.canMint ? can_mint : not_can_mint}</Text>
                                    <Text>-{t('interface.audit.result.burn')}: {response.contractFunctionality.canBurn ? can_burn : not_can_burn}</Text>
                                    <Text>-{t('interface.audit.result.cooldown')}: {response.contractFunctionality.hasTrading ? has_trading_cooldown : not_has_trading_cooldown}</Text>
                                    <br />
                                    <Text fontSize={'xl'} fontWeight={'bold'}>⚠️ {t('interface.audit.result.issues')}</Text>
                                    <Text>-{t('interface.audit.result.risk')}: {response.potentialIssue.externalcontractrisk ? external_contract_risk : not_external_contract_risk}</Text>
                                    <Text>-{t('interface.audit.result.suspicious')}: {response.potentialIssue.hasSuspicious ? has_suspicious_functions : not_has_suspicious_functions}</Text>
                                    <Text>-{t('interface.audit.result.proxy')}: {response.potentialIssue.proxyUpgradable ? 'Yes' : 'No'}</Text>
                                    <Text>-{t('interface.audit.result.honeypot')}: {response.potentialIssue.honeypot ? honeypot : not_honeypot}</Text>
                                    <Text>-{t('interface.audit.result.trading')}: {response.potentialIssue.pauseTrading ? pause_trading : not_pause_trading}</Text>
                                    <br />
                                    <Text fontSize={'xl'} fontWeight={'bold'}>🔒 {t('interface.audit.result.liquidity')}</Text>
                                    <Text>-{t('interface.audit.result.lock')}: {response.liquidity.lockDate}</Text>
                                    <Text>-{t('interface.audit.result.unlock')}: {response.liquidity.unlockDate}</Text>
                                    <br />
                                    <Text fontSize={'xl'} fontWeight={'bold'}>📌 {t('interface.audit.result.methods')}:</Text>
                                    <Text>
                                        {response.publicMethodvsFunc.map(item => item + ', ')}
                                    </Text>
                                    <br />
                                    <Text fontSize={'xl'} fontWeight={'bold'}>🦎 {t('interface.audit.result.market_data')}</Text>
                                    <Text>-{t('interface.audit.result.marketcap')}: {response.coingecko.marketCap.usd}</Text>
                                    <Text>-{t('interface.audit.result.volumn')}: {response.coingecko.volume.usd}</Text>
                                    <Text>-{t('interface.audit.result.price')}: {response.coingecko.currentPrice.usd}</Text>
                                </VStack>}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
            <DeleteAlert header={'Delete Bot'} msg={'Are you really delete your bot?'} isOpen={isAlertOpen} onClose={onAlertClose} clickOk={handleDeleteBot} />
            <CreateAssistant isOpen={isCreateBotOpen} onClose={onCreateBotClose}/>
        </Modal>
    )
}