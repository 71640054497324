import React, { useEffect, useState } from 'react'
import {
    Center,
    Flex,
    Text,
    Image
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { VERIFY_EMAIL_URL } from '../util/constant';
import { useTranslation } from 'react-i18next';

export default function Verify() {
    const {t} = useTranslation("common");
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.token === 'token') {

        }
        else {
            sendVerifyEmail(params.token);
        }
    }, []);

    const sendVerifyEmail = async (token) => {
        try {
            const response = await axios.post(VERIFY_EMAIL_URL, {token});
            if (response.data.success) {
                navigate('/login')
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <Flex w={'100%'} h={'100%'} backgroundImage={'/img/mindai.jpg'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'} display={['none', { md: 'flex' }]}>
                <Center w={'45%'} backgroundColor={'#0000008a'}>
                </Center>
                <Center w={'55%'} backgroundColor={'#15151C'}>
                    <div className="wrapper">
                        <Flex alignItems={'center'} justifyContent={'center'}>
                            <Image src='/logo.jpg' width={'4.5em'} height={'4.5em'} borderRadius={23} />
                            <Text className={'logo-text'}>{t("verify.title")}</Text>
                        </Flex>

                        <div className='verify'>
                            <span>{t("verify.desc1")}</span>
                            <br />
                            {t("verify.desc2")}
                            <br />
                            {t("verify.desc3")}
                        </div>
                    </div>
                    
                </Center>
            </Flex>
            <Flex w={'100%'} h={'100%'} backgroundImage={'/img/mindai.jpg'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'} display={['flex', { md: 'none' }]}>
                <Center w={'100%'} backgroundColor={'#0000008a'}>
                    <div className="wrapper">
                        <Flex alignItems={'center'} justifyContent={'center'}>
                            <Image src='/logo.jpg' width={'4.5em'} height={'4.5em'} borderRadius={23} />
                            <Text className={'logo-text'}>{t("verify.title")}</Text>
                        </Flex>

                        <div className='verify'>
                            <span>{t("verify.desc1")}</span>
                            <br />
                            {t("verify.desc2")}
                            <br />
                            {t("verify.desc3")}
                        </div>
                    </div>
                </Center>
            </Flex>
        </div>
    )
}
