import React, { useContext, useEffect, useState } from 'react'
import {
    Flex,
    Text,
    Image,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    MenuGroup,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    Heading,
    HStack,
    Input,
    Stack,
    InputLeftElement,
    InputRightElement,
    FormHelperText,
    InputGroup,
    chakra,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Icon,
    Stat,
    StatLabel,
    StatNumber,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    useClipboard
} from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import {
    useDisclosure,
    useToast,
    useColorModeValue
} from '@chakra-ui/react'
import {
    VscBellDot
} from 'react-icons/vsc'
import {
    IoAnalyticsSharp,
    IoLogoBitcoin,
    IoSearchSharp,
} from 'react-icons/io5';
import { MdOutlineToken } from "react-icons/md";
import {
    CiSettings,
    CiWallet
} from 'react-icons/ci'
import { FaUserAlt, FaLock } from "react-icons/fa";
import {
    HiOutlineSquares2X2
} from 'react-icons/hi2'
import { Logo } from './Logo'
import { OAuthButtonGroup } from './OAuthButtonGroup'
import { getInfo, userSignIn, userSignUp } from '../actions/auth'
import { useCookies } from 'react-cookie';
import Setting from './Setting';
import { Gallery } from './Gallery';
import { useNavigate } from 'react-router-dom';
import { useDisconnect } from 'wagmi';
import { jwtDecode } from 'jwt-decode'
import axios from 'axios';
import { API_URL, SERVER_URL } from '../util/constant';
import { BsPerson } from 'react-icons/bs';
import { BiPackage } from 'react-icons/bi';
import { AiOutlineFileProtect } from 'react-icons/ai';
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar from './Sidebar';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../provider/AppProvider';
import { FaRegCopy } from "react-icons/fa";
import { FiCheckSquare } from "react-icons/fi";
import Crypto from 'crypto-js';

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Feature = ({ text, icon, iconBg }) => {
    return (
        <Link>
            <Stack direction={'row'} align={'center'} py={2}>
                <Flex
                    w={8}
                    h={8}
                    align={'center'}
                    justify={'center'}
                    rounded={'full'}
                    bg={iconBg}>
                    {icon}
                </Flex>
                <Text fontWeight={600}>{text}</Text>
            </Stack>
        </Link>
    );
};

export default function Header(props) {
    const { onCopy, value, setValue, hasCopied } = useClipboard('');
    const { t, i18n } = useTranslation('common');
    const navigate = useNavigate();
    const { user, setUser } = useContext(AppContext);
    const { disconnect } = useDisconnect();
    const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure()
    const [cookies, setCookie, removeCookie] = useCookies(['token', 'name', 'model', 'lang', 'amount']);
    const toast = useToast();
    // const [inviteLink, setInviteLink] = useState('');
    const [data, setData] = useState({
        name: '',
        email: '',
        password: ''
    })
    const [info, setInfo] = useState({
        users: 0,
        token: 0,
        sub_weekly: 0,
        sub_monthly: 0,
        sub_yearly: 0
    });
    const [f_signIn, setF_SignIn] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isOpen: isSettingOpen, onClose: onSettingClose, onOpen: onSettingOpen } = useDisclosure();
    const { isOpen: isGalleryOpen, onClose: onGalleryClose, onOpen: onGalleryOpen } = useDisclosure();
    const { isOpen: isInfoOpen, onClose: onInfoClose, onOpen: onInfoOpen } = useDisclosure();
    const handleShowClick = () => setShowPassword(!showPassword);
    const getDappInfo = async () => {
        try {
            const response = await axios.get(API_URL + '/user/dapp', {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });
            setInfo({
                users: response.data.users,
                token: response.data.buy.token,
                sub_weekly: response.data.buy.subscription_weekly,
                sub_monthly: response.data.buy.subscription_monthly,
                sub_yearly: response.data.buy.subscription_yearly
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (cookies.token == "undefined" || cookies.token == undefined) {
            navigate('/login')
        }
        else {
            const decodeToken = jwtDecode(cookies.token);

            if (decodeToken.exp < new Date().getTime() / 1000) {
                disconnect();
                removeCookie('token', { path: '/' });
            }
            else {
                getInfo(cookies.token, handleSetCookie);
                getDappInfo();

                // setInviteLink(process.env.REACT_APP_FRONTEND_INVITE_URI + Crypto.AES.encrypt(JSON.stringify({ email: decodeToken.email, wallet: decodeToken.wallet }), "secret").toString());
            }
        }
    }, []);

    // const handleCopyClick = () => {
    //     console.log(inviteLink)
    //     const msg = inviteLink;
    //     setValue(msg);
    //     onCopy();
    // }

    const handleSetCookie = (name, model, lang, amount, avatar) => {
        setCookie('name', name, { path: '/' });
        setCookie('model', model, { path: '/' });
        setCookie('lang', lang, { path: '/' });
        setCookie('amount', amount, { path: '/' });
        setUser({
            ...user,
            name: name,
            avatar: avatar
        })
        i18n.changeLanguage(lang);
    }

    useEffect(() => {
        if (cookies.token === undefined || cookies.token == 'undefined') {
            navigate('/login');
        }
    }, [cookies.token]);

    // const handleSetName = (val) => {
    //     setData({...data, name: val});
    // }

    const handleSignIn = () => {
        if (data.password === '') {
            errorMsg('Password is required.');
            return;
        }
        if (data.email === '') {
            errorMsg('Email is required.');
            return;
        }
        if (!f_signIn && data.name === '') {
            errorMsg('Name is required.');
            return;
        }
        if (f_signIn) {
            userSignIn(data, handleSetCookie, errorMsg);
            onClose();
        }
        else {
            userSignUp(data, successMsg, errorMsg);
        }
    }

    const handleLogout = () => {
        disconnect();
        removeCookie('token', { path: '/' });
    }

    const errorMsg = (msg) => {
        toast({
            title: 'Something went wrong!',
            description: msg,
            status: 'error',
            duration: '3000',
            isClosable: true,
            position: 'top-right'
        })
    }

    const successMsg = (msg) => {
        toast({
            title: 'Success!',
            description: msg,
            status: 'success',
            duration: '3000',
            isClosable: true,
            position: 'top-right'
        })
    }

    useEffect(() => {
        setData({ ...data, name: cookies.name });
        // eslint-disable-next-line
    }, [cookies.name])

    const StatsCard = ({ title, stat, icon }) => {
        return (
            <Stat
                px={{ base: 2, md: 4 }}
                py={'5'}
                my={2}
                mx={2}
                shadow={'xl'}
                border={'1px solid'}
                borderColor={'gray'}
                rounded={'lg'}
            >
                <Flex justifyContent={'space-between'}>
                    <Box pl={{ base: 2, md: 4 }}>
                        <StatLabel fontWeight={'medium'} isTruncated>
                            {title}
                        </StatLabel>
                        <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
                            {stat}
                        </StatNumber>
                    </Box>
                    <Box
                        my={'auto'}
                        color={useColorModeValue('gray.800', 'gray.200')}
                        alignContent={'center'}
                    >
                        {icon}
                    </Box>
                </Flex>
            </Stat>
        )
    }

    return (
        <div className='header'>
            <Flex color={'white'} w={'35%'} pl={[0, { md: 10 }]} alignItems={'center'} justifyContent={'space-between'}>
                {/* <IconButton className='link' colorScheme={'white'} icon={<Link to='/statistics'><MdOutlineToken fontSize={30} /></Link>} variant={'ghost'} data-slot="value"/> */}
                <IconButton className='link' colorScheme={'white'} icon={<GiHamburgerMenu fontSize={30} />} variant={'ghost'} display={['block', { md: 'none' }]} onClick={onDrawerOpen} />
                <IconButton className='link' colorScheme={'white'} icon={<MdOutlineToken fontSize={30} />} variant={'ghost'} onClick={onInfoOpen} />
                <IconButton className='link' colorScheme={'white'} icon={<HiOutlineSquares2X2 fontSize={30} />} variant={'ghost'} onClick={onGalleryOpen} />
                {/* <Popover>
                    <PopoverTrigger>
                        <IconButton className='link' colorScheme={'white'} icon={<VscBellDot fontSize={30} />} variant={'ghost'} />
                    </PopoverTrigger>
                    <PopoverContent textAlign={'left'}>
                        <PopoverArrow />
                        <PopoverCloseButton color={'white'} />
                        <PopoverHeader textColor={'white'}>News!</PopoverHeader>
                        <PopoverBody textColor={'white'}>
                            <Feature
                                icon={
                                    <Icon as={IoAnalyticsSharp} color={'yellow.500'} w={5} h={5} />
                                }
                                iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                                text={'Business Planning'}
                            />
                            <Feature
                                 py={1}
                                icon={
                                    <Icon as={IoLogoBitcoin} color={'green.500'} w={5} h={5} />
                                }
                                iconBg={useColorModeValue('green.100', 'green.900')}
                                text={'Financial Planning'}
                            />
                            <Feature
                                py={1}
                                icon={
                                    <Icon as={IoSearchSharp} color={'purple.500'} w={5} h={5} />
                                }
                                iconBg={useColorModeValue('purple.100', 'purple.900')}
                                text={'Market Analysis'}
                            />
                        </PopoverBody>
                    </PopoverContent>
                </Popover> */}
                <IconButton className='link' colorScheme={'white'} icon={<Link to='/price'><CiWallet fontSize={30} /></Link>} variant={'ghost'} />
                <IconButton className='link' colorScheme={'white'} icon={<CiSettings fontSize={30} />} variant={'ghost'} onClick={onSettingOpen} />
            </Flex>
            <Flex alignItems={'center'} pr={[0, { md: 5 }]}>
                {cookies.token === undefined && <Link className='link' style={{ textDecoration: 'none' }} onClick={onOpen}>Login</Link>}
                {cookies.token !== undefined && <Flex alignItems={'center'}>
                    <Text color={'#5D5D5D'} fontSize={'lg'} display={['none', { lg: 'block' }]}>{t('header.token')}: &nbsp;</Text>
                    <Text color={'white'} fontSize={'lg'} pr={20} display={['none', { lg: 'block' }]}>{cookies.amount}</Text>
                    <Text color={'#5D5D5D'} fontSize={'lg'} display={['none', { md: 'block' }]}>{t('header.hello')},</Text>
                    <Text color={'white'} fontSize={'lg'} px={2} display={['none', { md: 'block' }]}>{user.name}</Text>
                    {/* <Text color={'white'} px={2}>{data.name}</Text> */}
                    <Menu>
                        <MenuButton
                            // as={Image}
                            aria-label='Options'
                            variant='outline'
                        >
                            <Image src={SERVER_URL + user.avatar} borderRadius={"50px"} w={'40px'} h={'40px'} />
                        </MenuButton>
                        <MenuList>
                            <MenuGroup title={t('header.profile')} textAlign={'left'} display={['block', { lg: 'none' }]}>
                                <MenuItem display={['block', { md: 'none' }]}>{cookies.name}</MenuItem>
                                <MenuItem display={['block', { lg: 'none' }]}>{cookies.amount}</MenuItem>
                            </MenuGroup>
                            <MenuDivider display={['block', { lg: 'none' }]} />
                            <MenuItem onClick={handleLogout}>{t('header.logout')}</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>}
            </Flex>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Sign In</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Container maxW="lg" py={{ base: '2', md: '4' }} px={{ base: '0', sm: '8' }}>
                            <Stack spacing="8">
                                <Stack spacing="6">
                                    <Logo />
                                    {f_signIn && <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                                        <Heading size={{ base: 'xs', md: 'sm' }}>Log in to your account</Heading>
                                        <Text color="fg.muted">
                                            Don't have an account? <Link onClick={() => setF_SignIn(false)}>Sign up</Link>
                                        </Text>
                                    </Stack>}
                                    {!f_signIn && <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                                        <Heading size={{ base: 'xs', md: 'sm' }}>Sign Up</Heading>
                                        <Text color="fg.muted">
                                            Already a user? <Link onClick={() => setF_SignIn(true)}>Sign in</Link>
                                        </Text>
                                    </Stack>}
                                </Stack>
                                <Box
                                    py={{ base: '0', sm: '8' }}
                                    px={{ base: '4', sm: '10' }}
                                    bg={{ base: 'transparent', sm: 'bg.surface' }}
                                    boxShadow={{ base: 'none', sm: 'md' }}
                                    borderRadius={{ base: 'none', sm: 'xl' }}
                                >
                                    <Stack spacing="6">
                                        <Stack spacing="5">
                                            {!f_signIn && <FormControl>
                                                <InputGroup>
                                                    <InputLeftElement
                                                        pointerEvents="none"
                                                        children={<CFaUserAlt color="gray.300" />}
                                                    />
                                                    <Input
                                                        type="text"
                                                        placeholder="Full Name"
                                                        value={data.name}
                                                        onChange={e => setData({ ...data, name: e.target.value })}
                                                    />
                                                </InputGroup>
                                            </FormControl>}
                                            <FormControl>
                                                <InputGroup>
                                                    <InputLeftElement
                                                        pointerEvents="none"
                                                        children={<CFaUserAlt color="gray.300" />}
                                                    />
                                                    <Input
                                                        type="email"
                                                        placeholder="email address"
                                                        value={data.email}
                                                        onChange={e => setData({ ...data, email: e.target.value })}
                                                    />
                                                </InputGroup>
                                            </FormControl>
                                            <FormControl>
                                                <InputGroup>
                                                    <InputLeftElement
                                                        pointerEvents="none"
                                                        color="gray.300"
                                                        children={<CFaLock color="gray.300" />}
                                                    />
                                                    <Input
                                                        type={showPassword ? "text" : "password"}
                                                        placeholder="Password"
                                                        value={data.password}
                                                        onChange={e => setData({ ...data, password: e.target.value })}
                                                    />
                                                    <InputRightElement width="4.5rem">
                                                        <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                                                            {showPassword ? "Hide" : "Show"}
                                                        </Button>
                                                    </InputRightElement>
                                                </InputGroup>
                                                <FormHelperText textAlign="right">
                                                    {f_signIn && <Link>forgot password?</Link>}
                                                </FormHelperText>
                                            </FormControl>
                                        </Stack>
                                        <HStack justify="space-between">
                                        </HStack>
                                        <Stack spacing="6">
                                            <Button onClick={handleSignIn}>{f_signIn ? 'Sign In' : 'Sign Up'}</Button>
                                            <HStack>
                                                <Divider />
                                                <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                                                    or continue with
                                                </Text>
                                                <Divider />
                                            </HStack>
                                            <OAuthButtonGroup />
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Container>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Gallery isOpen={isGalleryOpen} onClose={onGalleryClose} setMessages={props.setMessages} />
            <Setting isOpen={isSettingOpen} onClose={onSettingClose} />
            <Modal
                isOpen={isInfoOpen}
                onClose={onInfoClose}
                size={'xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('info.title')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex>
                            <StatsCard
                                title={t('info.users')}
                                stat={info.users}
                                icon={<BsPerson size={'3em'} />}
                            />
                            <StatsCard
                                title={t('info.token')}
                                stat={info.token}
                                icon={<BiPackage size={'3em'} />}
                            />
                        </Flex>
                        <Flex>
                            <StatsCard
                                title={t('info.sub-weekly')}
                                stat={info.sub_weekly}
                                icon={<AiOutlineFileProtect size={'3em'} />}
                            />
                            <StatsCard
                                title={t('info.sub-monthly')}
                                stat={info.sub_monthly}
                                icon={<AiOutlineFileProtect size={'3em'} />}
                            />
                        </Flex>
                        <StatsCard
                            title={t('info.sub-yearly')}
                            stat={info.sub_yearly}
                            icon={<AiOutlineFileProtect size={'3em'} />}
                        />
                        {/* <Box px={2} position={'relative'}>
                            <Text>Invite Link:</Text>
                            <Input variant='filled' value={inviteLink} _focusVisible={{ borderColor: 'none', background: 'rgba(255, 255, 255, 0.04)' }} />
                            {!hasCopied && <FaRegCopy style={{ position: 'absolute', right: '1rem', bottom: '0.5rem', cursor: 'pointer' }} fontSize={'1.5rem'} color='white' onClick={handleCopyClick} />}
                            {hasCopied && <FiCheckSquare style={{ position: 'absolute', right: '1rem', bottom: '0.5rem' }} color='white' fontSize={'1.5rem'} />}
                        </Box> */}
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Drawer
                isOpen={isDrawerOpen}
                onClose={onDrawerClose}
                placement='left'
            >
                <DrawerOverlay />
                <DrawerContent>
                    <Sidebar setModel={props.setModel}/>
                </DrawerContent>
            </Drawer>
        </div>
    )
}
