import axios from 'axios';
import { API_URL } from '../util/constant';

export const getHistory = (setData, data, cookie) => {
    axios.post(`${API_URL}/get/history`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            setData(res.data);
        })
        .catch(err => console.log(err));
}