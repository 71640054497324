import React, { useState } from 'react'
import {
    Center,
    Flex,
    Text,
    Image
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FORGET_PASSWORD_EMAIL_URL, CHANGE_PASSWORD_EMAIL_URL } from '../util/constant';
import { useTranslation } from "react-i18next";

export default function ForgetPassword() {
    const {t} = useTranslation("common");
    const navigate = useNavigate();
    const params = useParams();
    const toast = useToast();
    const [email, setEmail] = useState('');
    const [token, setToken] = useState(params.token);
    const [submit, setSubmit] = useState(false);
    const [password, setPassword] = useState('');
    const [repassword, setRePassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email === '') {
            toast({
                title: 'Email is required',
                status: 'error',
                isClosable: true,
                position: 'top-right'
            })
            return;
        }
        try {
            const response = await axios.post(FORGET_PASSWORD_EMAIL_URL, {email});
            const {success, message} = response.data;
            if (!success) {
                toast({
                    title: message,
                    status: 'error',
                    isClosable: true,
                    position: 'top-right'
                })
                return;
            }
            setSubmit(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeSubmit = async (e) => {
        e.preventDefault();
        if (password === '') {
            toast({
                title: 'Password is required',
                status: 'error',
                isClosable: true,
                position: 'top-right'
            })
            return;
        }
        if (repassword === '') {
            toast({
                title: 'Confirm Password is required',
                status: 'error',
                isClosable: true,
                position: 'top-right'
            })
            return;
        }
        if (password !== repassword) {
            toast({
                title: 'Check password again',
                status: 'error',
                isClosable: true,
                position: 'top-right'
            })
            return;
        }
        try {
            const response = await axios.post(CHANGE_PASSWORD_EMAIL_URL, {password, repassword, token});
            const {success, message} = response.data;
            if (!success) {
                toast({
                    title: message,
                    status: 'error',
                    isClosable: true,
                    position: 'top-right'
                })
                return;
            }
            navigate('/login');
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <Flex w={'100%'} h={'100%'} backgroundImage={'/img/mindai.jpg'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'} display={['none', { md: 'flex' }]}>
                <Center w={'45%'} backgroundColor={'#0000008a'}>
                </Center>
                <Center w={'55%'} backgroundColor={'#15151C'}>
                    <div className="wrapper">
                        <Flex alignItems={'center'} justifyContent={'center'}>
                            <Image src='/logo.jpg' width={'4.5em'} height={'4.5em'} borderRadius={23} />
                            <Text className={'logo-text'}>{t('forget.title')}</Text>
                        </Flex>

                        {<div className={!submit && token === 'token' ? 'form-inner' : 'none'}>
                            <form action='#'>
                                <div className="field">
                                    <input type="text" placeholder={t('forget.email')} value={email} onChange={e => setEmail(e.target.value)} onKeyDown={e => e.key == 'Enter' && handleSubmit(e)} />
                                </div>
                                <div className="field btn">
                                    <div className="btn-layer"></div>
                                    <input type="submit" value={t('forget.submit')} onClick={e => handleSubmit(e)}/>
                                </div>
                            </form>
                        </div>}
                        {<div className={submit && token === 'token' ? 'forget-submit submitted' : 'forget-submit'}>
                            <div>{t('forget.thanks')}</div>
                            <div>{t('forget.desc')}</div>
                        </div>}
                        {<div className={!submit && token !== 'token' ? 'form-inner' : 'none'}>
                            <form action='#'>
                                <div className="field">
                                    <input type="password" placeholder={t('forget.newpass')} value={password} onChange={e => setPassword(e.target.value)}/>
                                </div>
                                <div className="field">
                                    <input type="password" placeholder={t('forget.confirmpass')} value={repassword} onChange={e => setRePassword(e.target.value)} onKeyDown={e => e.key == 'Enter' && handleChangeSubmit(e)}/>
                                </div>
                                <div className="field btn">
                                    <div className="btn-layer"></div>
                                    <input type="submit" value={t('forget.submit')} onClick={e => handleChangeSubmit(e)}/>
                                </div>
                            </form>
                        </div>}
                    </div>
                    
                </Center>
            </Flex>
            <Flex w={'100%'} h={'100%'} backgroundImage={'/img/mindai.jpg'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'} display={['flex', { md: 'none' }]}>
                <Center w={'100%'} backgroundColor={'#0000008a'}>
                    <div className="wrapper">
                        <Flex alignItems={'center'} justifyContent={'center'}>
                            <Image src='/logo.jpg' width={'4.5em'} height={'4.5em'} borderRadius={23} />
                            <Text className={'logo-text'}>{t('forget.title')}</Text>
                        </Flex>

                        {<div className={!submit && token === 'token' ? 'form-inner' : 'none'}>
                            <form action='#'>
                                <div className="field">
                                    <input type="text" placeholder={t('forget.email')} value={email} onChange={e => setEmail(e.target.value)}  onKeyDown={e => e.key == 'Enter' && handleSubmit(e)} />
                                </div>
                                <div className="field btn">
                                    <div className="btn-layer"></div>
                                    <input type="submit" value={t('forget.submit')} onClick={e => handleSubmit(e)}/>
                                </div>
                            </form>
                        </div>}
                        {<div className={submit && token === 'token' ? 'forget-submit submitted' : 'forget-submit'}>
                            <div>{t('forget.thanks')}</div>
                            <div>{t('forget.desc')}</div>
                        </div>}
                        {<div className={!submit && token !== 'token' ? 'form-inner' : 'none'}>
                            <form action='#'>
                                <div className="field">
                                    <input type="password" placeholder={t('forget.newpass')} value={password} onChange={e => setPassword(e.target.value)}/>
                                </div>
                                <div className="field">
                                    <input type="password" placeholder={t('forget.confirmpass')} value={repassword} onChange={e => setRePassword(e.target.value)} onKeyDown={e => e.key == 'Enter' && handleChangeSubmit(e)}/>
                                </div>
                                <div className="field btn">
                                    <div className="btn-layer"></div>
                                    <input type="submit" value={t('forget.submit')} onClick={e => handleChangeSubmit(e)}/>
                                </div>
                            </form>
                        </div>}
                    </div>
                </Center>
            </Flex>
        </div>
    )
}
