import axios from 'axios';
import { API_URL } from "../util/constant"

export const generateImage = (cookie, data, setData, next) => {
    axios.post(`${API_URL}/image/generate`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            setData(res.data);
            next();
        })
        .catch(err => console.log(err));
}