import axios from 'axios';
import { API_URL } from "../util/constant"

export const userSignIn = (data, setData, toast) => {
    axios.post(`${API_URL}/user/signin`, data)
        .then(res => {
            setData(res.data);
            // setName(res.data.name);
        })
        .catch(err => {
            toast(err.response.data);
        });
}

export const userSignUp = (data, successMsg, errorMsg) => {
    axios.post(`${API_URL}/user/signup`, data)
        .then(res => {
            successMsg('Sign up successful!')
        })
        .catch(err => {
            errorMsg(err.response.data);
        });
}

export const changeName = (data, cookie, setCookie, error, success) => {
    axios.post(`${API_URL}/user/update`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            setCookie(data.name);
            success('Successful!');
        })
        .catch(err => error(err.response.data));
}

export const getInfo = (cookie, setData) => {
    axios.post(`${API_URL}/user/info`,{message: ''}, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            setData(res.data.name, res.data.model, res.data.language, res.data.token, res.data.avatar);
        })
        .catch(err => console.log(err));
}

export const changeModel = (cookie, data) => {
    axios.post(`${API_URL}/user/model`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            console.log(res.data);
        })
        .catch(err => console.log(err));
}

export const changeLang = (cookie, data) => {
    axios.post(`${API_URL}/user/lang`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            console.log(res.data);
        })
        .catch(err => console.log(err));
}

export const consumeToken = (cookie, data, setData) => {
    axios.post(`${API_URL}/user/token`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            setData(res.data)
        })
        .catch(err => console.log(err));
}