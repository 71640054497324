import React, { useContext, useState } from 'react'
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    ModalHeader,
    ModalContent,
    VStack,
    Text,
    Input,
    Textarea,
    Button,
    Flex,
    useToast,
    Tab,
    TabList,
    Tabs,
    TabPanel,
    TabPanels,
    RadioGroup,
    Radio,
    Stack,
    useColorMode,
    UnorderedList,
    ListItem,
    Switch,
    OrderedList,
    ButtonGroup,
    Select
} from '@chakra-ui/react'
import { FileUpload } from 'primereact/fileupload'
import 'react-image-upload/dist/index.css'
import "primereact/resources/themes/lara-dark-teal/theme.css";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import axios from 'axios';
import { CREATE_ASSISTANTS_URL } from '../util/constant';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getPrompts } from '../actions/prompts';
import { AppContext } from '../provider/AppProvider';
import { FaInfo } from "react-icons/fa";
import { RiRobot3Line } from "react-icons/ri";
import { FaBrain } from "react-icons/fa";
import { BsPatchQuestionFill } from "react-icons/bs";

export default function CreateAssistant({ isOpen, onClose }) {
    const { t, i18n } = useTranslation("common");
    const { colorMode } = useColorMode();
    const { setModes, setFlag } = useContext(AppContext);
    const navigate = useNavigate();
    const toast = useToast();
    const [cookies] = useCookies(['token']);
    const [data, setData] = useState({
        img: null,
        name: '',
        content: '',
        temperature: 0.2,
        max_tokens: 100,
        llm: 'smartest',
        enable_ques: false,
        questions: [],
        intel_text: '',
        intel_docs: null,
        links: [],
        smart_links: [],
        personality: ''
    })
    const [question, setQuestion] = useState('');
    const [link, setLink] = useState('');
    const [smartLink, setSmartLink] = useState('');
    const [edit, setEdit] = useState(-1);
    const [tabIndex, setTabIndex] = useState(0);
    const handleFileUpload = (e) => {
        setData({
            ...data,
            img: e.files[0]
        })
    }

    const handleIntelFileUpload = e => {
        if (e.files[0] === null) return;
        const fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(e.files[0]);
    }

    const handleFileRead = async (e) => {
        const content = e.target.result;
        setData({
            ...data,
            intel_docs: content
        });
    };

    const handleCreateAssistant = async () => {
        if (data.name === '') {
            toast({
                title: 'Name is required.',
                status: 'error',
                isClosable: true,
                position: 'top-right'
            })
            return;
        }
        if (data.content === '') {
            toast({
                title: 'Prompt is required.',
                status: 'error',
                isClosable: true,
                position: 'top-right'
            })
            return;
        }
        if (data.img === null) {
            toast({
                title: 'Image is required.',
                status: 'error',
                isClosable: true,
                position: 'top-right'
            })
            return;
        }
        const formData = new FormData();
        formData.append('file', data.img);
        formData.append('data', JSON.stringify(data));
        if (cookies.token === undefined) {
            return;
        }
        try {
            await axios.post(CREATE_ASSISTANTS_URL, formData, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            })
            setData({ 
                name: '', 
                content: '', 
                img: null,  
                temperature: 0.2,
                max_tokens: 100,
                llm: 'smartest',
                enable_ques: false,
                questions: [],
                intel_text: '',
                intel_docs: null,
                links: [],
                smart_links: [],
                personality: ''
            });
            setTabIndex(0);
            onClose();
            if (cookies.token == undefined || cookies.token == 'undefined') return navigate('/login');
            getPrompts(cookies.token, setModes, setFlag);
        } catch (error) {
            console.log(error);
        }
    }
    const handleChangeLLM = (e) => {
        if (e === 'smartest') {
            setData({
                ...data,
                llm: 'smartest',
                temperature: 0.2,
                max_tokens: 100
            })
        }
        else {
            setData({
                ...data,
                llm: 'fastest',
                temperature: 0.8,
                max_tokens: 50
            })
        }
    }

    const handleAddQuestion = () => {
        if (edit >= 0) {
            const newQuestions = data.questions.map((item, index) => {
                return index === edit ? question : item
            });
            setData({
                ...data,
                questions: newQuestions
            })
            setQuestion('')
            setEdit(-1);
        }
        if (data.questions.length === 3 || question === '') {
            return;
        }
        if (edit < 0) {
            setData({
                ...data,
                questions: [...data.questions, question]
            })
        }
        setQuestion('');
    }

    const handleAddLink = () => {
        const index = data.links.findIndex(item => item == link);
        if (index < 0) {
            setData({
                ...data,
                links: [...data.links, link]
            })
            setLink('');
        }
    }

    const handleAddSmartLink = () => {
        const index = data.smart_links.findIndex(item => item == smartLink);
        if (index < 0) {
            setData({
                ...data,
                smart_links: [...data.smart_links, smartLink]
            })
            setSmartLink('');
        }
    }

    const handleDeleteQuestion = (index) => {
        const newQuestions = data.questions.filter((item, idx) => idx !== index);
        setData({
            ...data,
            questions: newQuestions
        })
    }

    const handleEditQuestion = (index) => {
        if (data.questions[index] == null) return;
        setEdit(index);
        setQuestion(data.questions[index]);
    }

    const handleDeleteLink = (index) => {
        const newLinks = data.links.filter((item, idx) => idx != index);
        setData({
            ...data,
            links: newLinks
        })
    }

    const handleDeleteSmartLink = (index) => {
        const newLinks = data.smart_links.filter((item, idx) => idx != index);
        setData({
            ...data,
            smart_links: newLinks
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={'xl'}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('content.create_bot.title')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Tabs index={tabIndex}>
                        <TabList>
                            <Tab w={'25%'}>
                                <FaInfo />
                                &nbsp;&nbsp;
                                <Text textOverflow={'ellipsis'} whiteSpace={'nowrap'} overflow={'hidden'}>
                                    {t('content.create_bot.basic.title')} *
                                </Text>
                            </Tab>
                            <Tab w={'25%'}>
                                <RiRobot3Line />
                                &nbsp;&nbsp;
                                <Text  textOverflow={'ellipsis'} whiteSpace={'nowrap'} overflow={'hidden'}>
                                    {t('content.create_bot.personality.title')}
                                </Text>
                            </Tab>
                            <Tab w={'25%'}>
                                <FaBrain />
                                &nbsp;&nbsp;
                                <Text textOverflow={'ellipsis'} whiteSpace={'nowrap'} overflow={'hidden'}>
                                    {t('content.create_bot.intelligence.title')}
                                </Text>
                            </Tab>
                            <Tab w={'25%'}>
                                <BsPatchQuestionFill />
                                &nbsp;&nbsp;
                                <Text textOverflow={'ellipsis'} whiteSpace={'nowrap'} overflow={'hidden'}>
                                    {t('content.create_bot.questions.title')}
                                </Text>
                            </Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <VStack>
                                    <Text alignSelf={'start'}>{t('content.create_bot.basic.name')}</Text>
                                    <Input placeholder={t('content.create_bot.basic.name_place')} value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                                    <Flex alignItems={'center'} position={'relative'} w={'full'} display={['block', {md: 'flex'}]}>
                                        <Flex alignItems={'center'} w={['100%', {md: '50%'}]} pr={'1rem'} justifyContent={'space-between'}>
                                            <Text left={0}>{t('content.create_bot.basic.image')}: </Text>
                                            <FileUpload mode="basic" className='upload_btn' chooseLabel={t('content.create_bot.basic.image_place')} name="demo[]" accept="image/*" maxFileSize={10000000} onSelect={e => handleFileUpload(e)} />
                                        </Flex>
                                        <Flex w={['100%', {md: '50%'}]} justifyContent={'space-between'} alignItems={'center'}>
                                            <Text>{t('content.create_bot.basic.llm')}:&nbsp;</Text>
                                            <Select defaultValue={data.llm} onChange={e => handleChangeLLM(e)}>
                                                <option value='smartest'>{t('content.create_bot.basic.llm_smarter')}</option>
                                                <option value='fastest'>{t('content.create_bot.basic.llm_fast')}</option>
                                            </Select>
                                            {/* <RadioGroup value={data.llm} onChange={e => handleChangeLLM(e)}>
                                                <Stack direction='row'>
                                                    <Radio value='smartest'>{t('content.create_bot.basic.llm_smarter')}</Radio>
                                                    <Radio value='fastest'>{t('content.create_bot.basic.llm_fast')}</Radio>
                                                </Stack>
                                            </RadioGroup> */}
                                        </Flex>
                                    </Flex>
                                    <Textarea maxLength={60} placeholder={t('content.create_bot.basic.prompt')} value={data.content} onChange={e => setData({ ...data, content: e.target.value })} />
                                    <Text fontSize={'sm'}>{t('content.create_bot.basic.describe')}</Text>
                                    <Button onClick={() => setTabIndex(1)}>{t('content.create_bot.next')}</Button>
                                </VStack>
                            </TabPanel>
                            <TabPanel>
                                <Text>{t('content.create_bot.personality.title')}</Text>
                                <Textarea value={data.personality} onChange={e => setData({...data, personality: e.target.value})}/>
                                <Text fontSize={'sm'}>{t('content.create_bot.personality.desc')}</Text>
                                <Flex>
                                    <Button mt={2} display={'flex'} mx={'auto'} onClick={() => setTabIndex(0)}>{t('content.create_bot.prev')}</Button>
                                    <Button mt={2} display={'flex'} mx={'auto'} onClick={() => setTabIndex(2)}>{t('content.create_bot.next')}</Button>
                                </Flex>
                            </TabPanel>
                            <TabPanel>
                                <Flex>
                                    <Text display={'flex'} margin={'auto'} fontSize={'lg'}>{t('content.create_bot.intelligence.desc1')}</Text>
                                </Flex>
                                <Flex mb={4}>
                                    <Text margin={'auto'} fontSize={'sm'}>{t('content.create_bot.intelligence.desc2')}</Text>
                                </Flex>
                                <Tabs variant='soft-rounded' colorScheme='green'>
                                    <TabList>
                                        <Tab w={'25%'}>{t('content.create_bot.intelligence.document')}</Tab>
                                        <Tab w={'25%'}>{t('content.create_bot.intelligence.link')}</Tab>
                                        <Tab w={'25%'}>{t('content.create_bot.intelligence.smart_link')}</Tab>
                                        <Tab w={'25%'}>{t('content.create_bot.intelligence.text')}</Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            <div className="card">
                                                <FileUpload className={colorMode === 'light' ? 'light-file' : 'dark-file'} mode='basic' chooseLabel={t('content.create_bot.intelligence.file')} name="demo[]" accept="text/*" maxFileSize={1000000} onSelect={e => handleIntelFileUpload(e)} />
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <Text fontSize={'small'}>{t('content.create_bot.intelligence.link_desc')}</Text>
                                            <Flex mt={4} w={'full'}>
                                                <Input mr={2} value={link} onChange={e => setLink(e.target.value)} />
                                                <Button onClick={handleAddLink}>{t('content.create_bot.questions.add')}</Button>
                                            </Flex>
                                            <OrderedList w={'full'} px={2}>
                                                {
                                                    data.links.map((item, index) => {
                                                        return <ListItem key={'ordered_list_link_'+index}>
                                                        <Flex alignItems={'center'} justifyContent={'space-between'}>
                                                            <Text overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                                                                {item}
                                                            </Text>
                                                            <ButtonGroup>
                                                                <Button variant={'ghost'} onClick={() => handleDeleteLink(index)}><RiDeleteBin5Line /></Button>
                                                            </ButtonGroup>
                                                        </Flex>
                                                    </ListItem>
                                                    })
                                                }
                                            </OrderedList>
                                        </TabPanel>
                                        <TabPanel>
                                            <Text fontSize={'small'}>{t('content.create_bot.intelligence.smart_link_desc')}</Text>
                                            <Flex mt={4} w={'full'}>
                                                <Input mr={2} value={smartLink} onChange={e => setSmartLink(e.target.value)} />
                                                <Button onClick={handleAddSmartLink}>{t('content.create_bot.questions.add')}</Button>
                                            </Flex>
                                            <OrderedList w={'full'} px={2}>
                                                {
                                                    data.smart_links.map((item, index) => {
                                                        return <ListItem key={'ordered_list_link_'+index}>
                                                        <Flex alignItems={'center'} justifyContent={'space-between'}>
                                                            <Text overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                                                                {item}
                                                            </Text>
                                                            <ButtonGroup>
                                                                <Button variant={'ghost'} onClick={() => handleDeleteSmartLink(index)}><RiDeleteBin5Line /></Button>
                                                            </ButtonGroup>
                                                        </Flex>
                                                    </ListItem>
                                                    })
                                                }
                                            </OrderedList>
                                        </TabPanel>
                                        <TabPanel>
                                            <Textarea value={data.intel_text} onChange={e => setData({...data, intel_text: e.target.value})}/>
                                        </TabPanel>
                                        <Flex>
                                            <Button mt={2} display={'flex'} mx={'auto'} onClick={() => setTabIndex(1)}>{t('content.create_bot.prev')}</Button>
                                            <Button mt={2} display={'flex'} mx={'auto'} onClick={() => setTabIndex(3)}>{t('content.create_bot.next')}</Button>
                                        </Flex>
                                    </TabPanels>
                                </Tabs>
                            </TabPanel>
                            <TabPanel>
                                <Flex>
                                    <Text display={'flex'} margin={'auto'} fontSize={'lg'}>{t('content.create_bot.questions.starter')}</Text>
                                </Flex>
                                <Flex mb={4}>
                                    <Text margin={'auto'} fontSize={'sm'}>{t('content.create_bot.questions.desc')}</Text>
                                </Flex>
                                <Flex>
                                    <Text display={'flex'} margin={'auto'} fontSize={'lg'}>{t('content.create_bot.questions.important')}</Text>
                                </Flex>
                                <Flex mb={4} justifyContent={'center'}>
                                    <UnorderedList>
                                        <ListItem>
                                            <Text margin={'auto'} fontSize={'sm'}>{t('content.create_bot.questions.rule1')}</Text>
                                        </ListItem>
                                        <ListItem>
                                            <Text margin={'auto'} fontSize={'sm'}>{t('content.create_bot.questions.rule2')}</Text>
                                        </ListItem>
                                        <ListItem>
                                            <Text margin={'auto'} fontSize={'sm'}>{t('content.create_bot.questions.rule3')}</Text>
                                        </ListItem>
                                    </UnorderedList>
                                </Flex>
                                <Flex justifyContent={'center'}>
                                    <Switch colorScheme='teal' size='lg' isChecked={data.enable_ques} onChange={e => setData({...data, enable_ques: e.target.checked})}  />
                                    <Text>&nbsp;&nbsp;{t('content.create_bot.questions.enable')}</Text>
                                </Flex>
                                {
                                    data.enable_ques && <VStack>
                                        <Flex mt={4} w={'full'}>
                                            <Input mr={2} value={question} onChange={e => setQuestion(e.target.value)} />
                                            <Button onClick={handleAddQuestion}>{t('content.create_bot.questions.add')}</Button>
                                        </Flex>
                                        <OrderedList w={'full'} px={2}>
                                            {
                                                data.questions.map((item, index) => {
                                                    return <ListItem key={'ordered_list_'+index}>
                                                    <Flex alignItems={'center'} justifyContent={'space-between'}>
                                                        <Text overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                                                            {item}
                                                        </Text>
                                                        <ButtonGroup>
                                                            <Button variant={'ghost'} onClick={() => handleEditQuestion(index)}><FaRegEdit /></Button>
                                                            <Button variant={'ghost'} onClick={() => handleDeleteQuestion(index)}><RiDeleteBin5Line /></Button>
                                                        </ButtonGroup>
                                                    </Flex>
                                                </ListItem>
                                                })
                                            }
                                            
                                        </OrderedList>
                                    </VStack>
                                }
                                <Flex>
                                    <Button mt={2} display={'flex'} mx={'auto'} onClick={() => setTabIndex(2)}>{t('content.create_bot.prev')}</Button>
                                    <Button mt={2} display={'flex'} mx={'auto'} onClick={handleCreateAssistant}>{t('content.create_bot.save')}</Button>
                                </Flex>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
