import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import axios from 'axios';
import Home from './components/Home';
import Crypto from './components/Crypto';
import History from './components/History';
import Login from './components/Login';

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import AppProvider from "./provider/AppProvider";
import Statistic from './components/Statistic';
import Verify from './components/Verify';
import ForgetPassword from './components/ForgetPassword';

const config = getDefaultConfig({
    appName: 'My RainbowKit App',
    projectId: 'YOUR_PROJECT_ID',
    chains: [mainnet, polygon, optimism, arbitrum, base],
    ssr: true, // If your dApp uses server side rendering (SSR)
});

function App() {
    axios.defaults.headers.common['header'] = 'application/json';
    const queryClient = new QueryClient();

    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                <RainbowKitProvider>
                    <AppProvider>
                        <div className="App">
                            <Router>
                                <Routes>
                                    <Route path='/' element={<Home />} />
                                    <Route path='/price' element={<Crypto />} />
                                    <Route path='/history' element={<History />} />
                                    <Route path='/login' element={<Login />} />
                                    <Route path='/login/forget/:token' element={<ForgetPassword />} />
                                    <Route path='/user/verify-email/:token' element={<Verify />} />
                                    <Route path='/invite/ref' element={<Login />} />
                                    {/* <Route path='/statistics' element={<Statistic />} /> */}
                                </Routes>
                            </Router>
                            {/* <Home /> */}
                        </div>
                    </AppProvider>
                </RainbowKitProvider>
            </QueryClientProvider>
        </WagmiProvider>
    );
}

export default App;
