import axios from 'axios';
import { API_URL } from '../util/constant';

export const checkout = (data, cookie) => {
    axios.post(`${API_URL}/crypto/checkout`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            const {result} = res.data;

            let element = document.createElement('a');
            element.setAttribute('target', '_blank');
            element.setAttribute('href', result.url);
            element.click();
        })
        .catch(err => console.log(err));
}

export const checkout_subscription = (data, cookie) => {
    axios.post(`${API_URL}/crypto/checkout/subscription`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            const {result} = res.data;

            let element = document.createElement('a');
            element.setAttribute('target', '_blank');
            element.setAttribute('href', result.url);
            element.click();
        })
        .catch(err => console.log(err));
}

export const checkout_credit = (data, cookie) => {
    axios.post(`${API_URL}/crypto/checkout/credit`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            const {result} = res.data;

            let element = document.createElement('a');
            element.setAttribute('target', '_blank');
            element.setAttribute('href', result.url);
            element.click();
        })
        .catch(err => console.log(err));
}