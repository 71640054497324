import React, { useContext, useEffect, useState } from 'react'
import {
    Center,
    Flex,
    Text,
    Image,
    Box
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useAccount, useDisconnect } from 'wagmi';
import { useCookies } from 'react-cookie';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL, SIGN_IN_URL, SIGN_IN_WALLET_URL, SIGN_UP_URL, SIGN_UP_WALLET_URL } from '../util/constant';
import { AppContext } from '../provider/AppProvider';
import { validateEmail } from '../util/utils';
import { CustomConnectButton } from './CustomConnectButton';
import { useGoogleLogin } from '@react-oauth/google';
import { useLocation } from 'react-router-dom';
import { useDiscordLogin } from 'react-discord-login';
// import TwitterLogin from 'react-twitter-auth';
import { TwitterLogin } from 'react-twitter-login';
import { useTranslation } from "react-i18next";
import Crypto from 'crypto-js';

export default function Login() {
    const {t, i18n} = useTranslation("common");
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useToast();
    const { isConnected, address } = useAccount();
    const { setUser } = useContext(AppContext);
    const [cookies, setCookie] = useCookies(['token', 'name', 'model', 'lang', 'amount', 'subscription']);
    const [forget, setForget] = useState(false);
    const [sign, setSign] = useState(true);

    useEffect(() => {
        const getInvite = async () => {
            if (location.pathname == "/invite/ref") {
                try {
                    const byte = location.search.substring(2, location.search.length);
                    const bytes = Crypto.AES.decrypt(byte, "secret");
                    const jsonString = bytes.toString(Crypto.enc.Utf8);
                    const decode = JSON.parse(jsonString);
                    await axios.post(API_URL + '/invite', decode);                
                } catch (error) {
                    console.log(error);
                }
            }
        }
        getInvite();
    }, []);

    const login = useGoogleLogin({
        onSuccess: tokenResponse => {
            const { access_token } = tokenResponse;
            if (sign) {
                axios.post(`${API_URL}/user/signin/google`, {access_token})
                    .then(response => {
                        const {success} = response.data;
                        if (success === false) {
                            toast({
                                title: 'Failed',
                                description: response.data.message,
                                status: 'error',
                                isClosable: true,
                                position: 'top-right'
                            })
                            return;
                        }
                        setUser(response.data);
                        i18n.changeLanguage(response.data.lang);
                        setCookie('token', response.data.token, {path: '/'});
                        navigate('/');
                    })
                    .catch(err => console.log(err));
            }
            else {
                axios.post(`${API_URL}/user/signup/google`, {access_token})
                    .then(response => {
                        const {success} = response.data;
                        if (success === false) {
                            toast({
                                title: 'Failed',
                                description: response.data.message,
                                status: 'error',
                                isClosable: true,
                                position: 'top-right'
                            })
                            return;
                        }
                        handleLoginClick();
                    })
                    .catch(err => console.log(err));
            }
        },
        onError: errorResponse => console.error(errorResponse),
    });
    const { disconnect } = useDisconnect()
    const [data, setData] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        const extractToken = async () => {
            const hash = location.hash;
            const queryParams = new URLSearchParams(hash.substring(1)); // Remove the # at the start
            const accessToken = queryParams.get('access_token');
            if (accessToken) {
                console.log('Access Token:', accessToken);

                try {
                    const response = await axios.get("https://discordapp.com/api/users/@me", {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    });
    
                    console.log(response.data)                    
                } catch (error) {
                    console.log(error.message);
                }
        
            }
        };
      
        extractToken();
    }, [location]);

    const [signData, setSignData] = useState({
        email: '',
        password: '',
        wallet: ''
    })

    const handleSignIn = async (e) => {
        e.preventDefault();
        if (data.email === '') {
            toast({
                title: 'Email is required',
                status: 'error',
                position: 'top-right',
                isClosable: true,
            })
            return;
        }
        if (data.password === '') {
            toast({
                title: 'Password is required',
                status: 'error',
                position: 'top-right',
                isClosable: true,
            })
            return;
        }
        try {
            const response = await axios.post(SIGN_IN_URL, {
                ...data,
                wallet: ''
            });
            const {success} = response.data;
            if (success === false) {
                toast({
                    title: 'Failed',
                    description: response.data.message,
                    status: 'error',
                    isClosable: true,
                    position: 'top-right'
                })
                return;
            }
            setUser(response.data)
            i18n.changeLanguage(response.data.lang);
            setCookie('token', response.data.token, {path: '/'});
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    }

    const handleSignInWithWallet = async () => {
        try {
            let response;
            if (sign) {
                response = await axios.post(SIGN_IN_WALLET_URL, {
                    email: '',
                    password: '',
                    wallet: address
                });
            }
            else {
                response = await axios.post(SIGN_UP_WALLET_URL, {
                    email: '',
                    password: '',
                    wallet: address
                });
            }

            const {success} = response.data;
            if (!success) {
                toast({
                    title: 'Failed',
                    description: response.data.message,
                    status: 'error',
                    isClosable: true,
                    position: 'top-right'
                })
                disconnect();
                return;
            }
            if (sign) {
                setUser(response.data)
                i18n.changeLanguage(response.data.lang);
                setCookie('token', response.data.token, {path: '/'});
                navigate('/');
            }
            else {
                handleLoginClick();
            }
            disconnect();
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (isConnected) {
            handleSignInWithWallet();
        }
    }, [isConnected]);

    useEffect(() => {
        if (cookies.token !== undefined) {
            navigate('/');
        }
    }, [cookies.token]);

    const handleLoginClick = () => {
        setSign(true);
        const loginForm = document.querySelector("form.login");
        loginForm.style.marginLeft = "0%";
    }

    const handleSignClick = () => {
        setSign(false)
        const loginForm = document.querySelector("form.login");
        loginForm.style.marginLeft = "-50%";
    }
    
    const handleMobileLoginClick = () => {
        const loginForm = document.querySelector("form.login-mobile");
        loginForm.style.marginLeft = "0%";
    }

    const handleMobileSignClick = () => {
        const loginForm = document.querySelector("form.login-mobile");
        loginForm.style.marginLeft = "-50%";
    }

    const handleSignUp = async (e) => {
        e.preventDefault();
        if (signData.email === '' && signData.wallet === '') {
            toast({
                title: 'Email or wallet are required',
                status: 'error',
                position: 'top-right',
                isClosable: true,
            })
            return;
        }
        // if (signData.wallet === '') {
        //     toast({
        //         title: 'Wallet is required',
        //         status: 'error',
        //         position: 'top-right',
        //         isClosable: true,
        //     })
        //     return;
        // }
        if (signData.email !== '' && signData.password === '') {
            toast({
                title: 'Password is required',
                status: 'error',
                position: 'top-right',
                isClosable: true,
            })
            return;
        }
        if (signData.email !== '' && !validateEmail(signData.email)) {
            toast({
                title: 'Email is invalid',
                status: 'error',
                position: 'top-right',
                isClosable: true,
            })
            return;
        }
        try {
            const response = await axios.post(SIGN_UP_URL, signData);
            const {success} = response.data;
            if (!success) {
                toast({
                    title: 'Failed',
                    description: response.data.message,
                    position: 'top-right',
                    isClosable: true,
                    status: 'error'
                })
                return;
            }
            if (success && signData.email !== '') {
                navigate('/user/verify-email/token')
            }
            if (success && signData.email === '') {
                handleLoginClick();
                handleMobileLoginClick();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleGoogleSignIn = (e) => {
        e.preventDefault();
        login();
    }

    const discordLoginParams = {
        clientId: process.env.REACT_APP_DISCORD_CLIENT_ID,
        redirectUri: process.env.REACT_APP_DISCORD_REDIRECT_URI,
        responseType: 'token', // or 'code'
        scopes: ['identify', 'email'],
        onSuccess: response => {
            axios.post(`${API_URL}/user/signin/discord`, {email: response.user.email})
                .then(response => {
                    const {success} = response.data;
                    if (success === false) {
                        toast({
                            title: 'Failed',
                            description: response.data.message,
                            status: 'error',
                            isClosable: true,
                            position: 'top-right'
                        })
                        return;
                    }
                    setUser(response.data)
                    i18n.changeLanguage(response.data.lang);
                    setCookie('token', response.data.token, {path: '/'});
                    navigate('/');
                })
                .catch(err => console.log(err));
        },
        onFailure: error => {
            // Handle login failure
            console.error('Login failed:', error);
        },
    };
    const { buildUrl: signinBuildUrl, isLoading: signinIsLoading } = useDiscordLogin(discordLoginParams);
    const discordLoginParams1 = {
        clientId: process.env.REACT_APP_DISCORD_CLIENT_ID,
        redirectUri: process.env.REACT_APP_DISCORD_REDIRECT_URI,
        responseType: 'token', // or 'code'
        scopes: ['identify', 'email'],
        onSuccess: response => {
            axios.post(`${API_URL}/user/signup/discord`, {email: response.user.email})
                .then(response => {
                    const {success} = response.data;
                    if (success === false) {
                        toast({
                            title: 'Failed',
                            description: response.data.message,
                            status: 'error',
                            isClosable: true,
                            position: 'top-right'
                        })
                        return;
                    }
                })
                .catch(err => console.log(err));
        },
        onFailure: error => {
            // Handle login failure
            console.error('Login failed:', error);
        },
    };
    const { buildUrl: signupBuildUrl, isLoading: signupIsLoading } = useDiscordLogin(discordLoginParams1);

    const handleDiscordSignIn = (e) => {
        e.preventDefault();
        window.location.href = signinBuildUrl();
    }

    const handleTwitterSignIn = (e) => {
        e.preventDefault();
    }

    const handleGoogleSignup = (e) => {
        e.preventDefault();
        setSign(false);
        login();
    }

    const handleDiscordSignup = (e) => {
        e.preventDefault();
        setSign(false);
        window.location.href = signupBuildUrl();
    }

    const handleTwitterSignup = (e) => {
        e.preventDefault();
    }

    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <Flex w={'100%'} h={'100%'} backgroundImage={'/img/mindai.jpg'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'} display={['none', { md: 'flex' }]}>
                <Center w={'45%'} backgroundColor={'#0000008a'}>
                </Center>
                <Center w={'55%'} backgroundColor={'#15151C'}>
                    {/* <VStack>
                        <Flex alignItems={'center'} pb={5}>
                            <Image src='./logo.jpg' width={{'2xl': "5em", xl: "4.5em", lg: "4em", md: "3.5em"}} height={{'2xl': "5em", xl: "4.5em", lg: "4em", md: "3.5em"}} borderRadius={23} />
                            <Text className='logo-text'>MIND AI</Text>
                        </Flex>
                        <ConnectButton label='Login with Wallet' />
                    </VStack> */}
                    {/* {sign && <SignIn change={changeSign} isMobile={'false'}/>}
                    {!sign && <SignUp change={changeSign} isMobile={'false'} />} */}
                    <div className="wrapper">
                        <Flex alignItems={'center'} justifyContent={'center'}>
                            <Image src='/logo.jpg' width={'4.5em'} height={'4.5em'} borderRadius={23} />
                            <Text className={'logo-text'}>{t('sign.logo')}</Text>
                        </Flex>

                        {!forget && <div className="form-container">
                            {/* <div className="slide-controls">
                                <input type="radio" name="slide" id="login" onClick={handleLoginClick}/>
                                <input type="radio" name="slide" id="signup" onClick={handleSignClick}/>
                                <label htmlFor="login" className="slide login">Login</label>
                                <label htmlFor="signup" className="slide signup">Signup</label>
                                <div className="slider-tab"></div>
                            </div> */}
                            <div className="form-inner">
                                <form action="#" className="login">
                                    <div className="field">
                                        <input type="text" placeholder={t('sign.signin.email')} value={data.email} onChange={e => setData({...data, email: e.target.value})} onKeyDown={e => e.key === 'Enter' && e.preventDefault()}/>
                                    </div>
                                    <div className="field">
                                        <input type="password" placeholder={t('sign.signin.password')} onChange={e => setData({...data, password: e.target.value})} onKeyDown={e => e.key === 'Enter' && handleSignIn(e)}/>
                                    </div>
                                    <div className="pass-link">
                                        <div>
                                            {t('sign.signin.info')}&nbsp;
                                            <span onClick={handleSignClick}>{t('sign.signin.signup')}</span>
                                        </div>
                                        <Link to={'/login/forget/token'}>{t('sign.signin.forget')}</Link>
                                    </div>
                                    <div className="field btn">
                                        <div className="btn-layer"></div>
                                        <input type="submit" value={t('sign.signin.title')} onClick={e => handleSignIn(e)}/>
                                    </div>
                                    <Box w={'full'} id='wallet-button' borderTop={'solid 0.1px #5d5d5d'} marginTop={2} fontSize={'1.2rem'} paddingTop={2} paddingBottom={2} display={'flex'} justifyContent={'space-between'}>
                                        <button className='google-btn' onClick={e => handleGoogleSignIn(e)}>
                                            <img src='/img/google.png' width={18} alt='google icon'/>
                                            &nbsp;
                                            Google
                                        </button>
                                        {/* <button className='google-btn' onClick={e => handleDiscordSignIn(e)}>
                                            <img src='/img/discord.png' width={18} alt='google icon'/>
                                        </button> */}
                                        {/* <button className='google-btn' onClick={e => handleTwitterSignIn(e)}>
                                            <img src='/img/twitter.png' width={18} alt='google icon'/>
                                        </button> */}
                                        {/* <TwitterLogin
                                            consumerKey='PyHxgJuyORZqhDiuKAne8LcxT'
                                            consumerSecret='RBqOgWJfflgk2GLGmKtHFnHituqvf3vROPfAqzOPpfKficIrI9'
                                            // authCallback={loginHandler}
                                            className='google-btn'
                                            children={<button onClick={e => handleTwitterSignIn(e)}><img src='/img/twitter.png' width={18} alt='google icon'/></button>}
                                        /> */}
                                        <CustomConnectButton />
                                    </Box>
                                </form>
                                <form action="#" className="signup">
                                    <div className="field">
                                        <input type="text" placeholder={t('sign.signup.email')} value={signData.email} onChange={e => setSignData({...signData, email: e.target.value})}/>
                                    </div>
                                    {/* <div className="field">
                                        <input type="text" placeholder="Wallet Address" value={signData.wallet} onChange={e => setSignData({...signData, wallet: e.target.value})}/>
                                    </div> */}
                                    <div className="field">
                                        <input type="password" placeholder={t('sign.signup.password')} value={signData.password} onChange={e => setSignData({...signData, password: e.target.value})} />
                                    </div>
                                    <div className="pass-link">
                                        <div>
                                            {t('sign.signup.info')}&nbsp;
                                            <span onClick={handleLoginClick}>{t('sign.signup.signin')}</span>
                                        </div>
                                    </div>
                                    <div className="field btn">
                                        <div className="btn-layer"></div>
                                        <input type="submit" value={t('sign.signup.title')} onClick={e => handleSignUp(e)}/>
                                    </div>
                                    <Box w={'full'} id='wallet-button' borderTop={'solid 0.1px #5d5d5d'} marginTop={2} fontSize={'1.2rem'} paddingTop={2} paddingBottom={2} display={'flex'} justifyContent={'space-between'}>
                                        <button className='google-btn' onClick={e => handleGoogleSignup(e)}>
                                            <img src='/img/google.png' width={18} alt='google icon'/>
                                            &nbsp;
                                            Google
                                        </button>
                                        {/* <button className='google-btn' onClick={e => handleDiscordSignup(e)}>
                                            <img src='/img/discord.png' width={18} alt='google icon'/>
                                        </button> */}
                                        {/* <button className='google-btn' onClick={e => handleTwitterSignIn(e)}>
                                            <img src='/img/twitter.png' width={18} alt='google icon'/>
                                        </button> */}
                                        {/* <TwitterLogin
                                            consumerKey='PyHxgJuyORZqhDiuKAne8LcxT'
                                            consumerSecret='RBqOgWJfflgk2GLGmKtHFnHituqvf3vROPfAqzOPpfKficIrI9'
                                            // authCallback={loginHandler}
                                            className='google-btn'
                                            children={<button onClick={e => handleTwitterSignup(e)}><img src='/img/twitter.png' width={18} alt='google icon'/></button>}
                                        /> */}
                                        <CustomConnectButton />
                                    </Box>
                                </form>
                            </div>
                        </div>}
                    </div>
                    
                </Center>
            </Flex>
            <Flex w={'100%'} h={'100%'} backgroundImage={'/img/mindai.jpg'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'} display={['flex', { md: 'none' }]}>
                <Center w={'100%'} backgroundColor={'#0000008a'}>
                    <div className="wrapper">
                        <Flex alignItems={'center'} justifyContent={'center'}>
                            <Image src='./logo.jpg' width={'4.5em'} height={'4.5em'} borderRadius={23} />
                            <Text className={'logo-text'}>{t('sign.logo')}</Text>
                        </Flex>

                        {!forget && <div className="form-container">
                            <div className="form-inner">
                                <form action="#" className="login-mobile">
                                    <div className="field">
                                        <input type="text" placeholder={t('sign.signin.email')} value={data.email} onChange={e => setData({...data, email: e.target.value})} />
                                    </div>
                                    <div className="field">
                                        <input type="password" placeholder={t('sign.signin.password')} onChange={e => setData({...data, password: e.target.value})}/>
                                    </div>
                                    <div className="pass-link">
                                        <div>
                                            {t('sign.signin.info')}&nbsp;
                                            <span onClick={handleMobileSignClick}>{t('sign.signin.signup')}</span>
                                        </div>
                                        <Link to={'/login/forget/token'}>{t('sign.signin.forget')}</Link>
                                    </div>
                                    <div className="field btn">
                                        <div className="btn-layer"></div>
                                        <input type="submit" value={t('sign.signin.title')} onClick={e => handleSignIn(e)} />
                                    </div>
                                    <Box w={'full'} id='wallet-button' borderTop={'solid 0.1px #5d5d5d'} marginTop={2} fontSize={'1.2rem'} padding={2} display={'flex'} justifyContent={'space-between'}>
                                        <button className='google-btn' onClick={e => handleGoogleSignIn(e)}>
                                            <img src='/img/google.png' width={18} alt='google icon'/>
                                            &nbsp;
                                            Google
                                        </button>
                                        {/* <button className='google-btn' onClick={e => handleDiscordSignIn(e)}>
                                            <img src='/img/discord.png' width={18} alt='google icon'/>
                                        </button>
                                        <button className='google-btn' onClick={e => handleTwitterSignIn(e)}>
                                            <img src='/img/twitter.png' width={18} alt='google icon'/>
                                        </button> */}
                                        <CustomConnectButton />
                                    </Box>
                                </form>
                                <form action="#" className="signup-mobile">
                                    <div className="field">
                                        <input type="text" placeholder={t('sign.signup.email')} value={signData.email} onChange={e => setSignData({...signData, email: e.target.value})}/>
                                    </div>
                                    {/* <div className="field">
                                        <input type="text" placeholder="Wallet Address" value={signData.wallet} onChange={e => setSignData({...signData, wallet: e.target.value})}/>
                                    </div> */}
                                    <div className="field">
                                        <input type="password" placeholder={t('sign.signup.password')} value={signData.password} onChange={e => setSignData({...signData, password: e.target.value})} />
                                    </div>
                                    <div className="pass-link">
                                        <div>
                                            {t('sign.signup.info')}&nbsp;
                                            <span onClick={handleMobileLoginClick}>{t('sign.signup.signin')}</span>
                                        </div>
                                    </div>
                                    <div className="field btn">
                                        <div className="btn-layer"></div>
                                        <input type="submit" value={t('sign.signup.title')} onClick={e => handleSignUp(e)}/>
                                    </div>
                                    <Box w={'full'} id='wallet-button' borderTop={'solid 0.1px #5d5d5d'} marginTop={2} fontSize={'1.2rem'} paddingTop={2} paddingBottom={2} display={'flex'} justifyContent={'space-between'}>
                                        <button className='google-btn' onClick={e => handleGoogleSignup(e)}>
                                            <img src='/img/google.png' width={18} alt='google icon'/>
                                            &nbsp;
                                            Google
                                        </button>
                                        {/* <button className='google-btn' onClick={e => handleDiscordSignup(e)}>
                                            <img src='/img/discord.png' width={18} alt='google icon'/>
                                        </button> */}
                                        {/* <button className='google-btn' onClick={e => handleTwitterSignIn(e)}>
                                            <img src='/img/twitter.png' width={18} alt='google icon'/>
                                        </button> */}
                                        {/* <TwitterLogin
                                            consumerKey='PyHxgJuyORZqhDiuKAne8LcxT'
                                            consumerSecret='RBqOgWJfflgk2GLGmKtHFnHituqvf3vROPfAqzOPpfKficIrI9'
                                            // authCallback={loginHandler}
                                            className='google-btn'
                                            children={<button onClick={e => handleTwitterSignup(e)}><img src='/img/twitter.png' width={18} alt='google icon'/></button>}
                                        /> */}
                                        <CustomConnectButton />
                                    </Box>
                                </form>
                            </div>
                        </div>}
                    </div>
                </Center>
            </Flex>
        </div>
    )
}
