import React, { useEffect, useState } from 'react'
import {
    Box,
    Stack,
    HStack,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Tag,
    // Input,
    // InputGroup,
    // InputLeftElement,
    // InputRightAddon,
    Flex,
    Center,
    Wrap,
    WrapItem
} from '@chakra-ui/react';
import { TiArrowBack } from "react-icons/ti";
import { FaCheckCircle } from 'react-icons/fa';
import { useCookies } from 'react-cookie';
import { checkout, checkout_credit, checkout_subscription } from '../actions/cryptomus';
import { Link } from 'react-router-dom';
import { HiOutlineInboxStack } from "react-icons/hi2";
import { Search2Icon } from "@chakra-ui/icons";
import { getHistory } from '../actions/history';
import { payment_status } from '../util/constant';
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import { useTranslation } from 'react-i18next';

function PriceWrapper({ children }) {
    return (
        <WrapItem justifyContent={'center'}>
            <Box
                mb={4}
                shadow="base"
                borderWidth="1px"
                alignSelf={{ base: 'center', lg: 'flex-start' }}
                borderColor={useColorModeValue('gray.200', 'gray.500')}
                borderRadius={'xl'}>
                {children}
            </Box>
        </WrapItem>
    );
}

export default function Crypto() {
    const { t } = useTranslation("common");
    const [cookies] = useCookies(['token']);
    const [trans, setTrans] = useState([]);
    const [data, setData] = useState({
        search: '',
        current: 1,
        pages: 1,
        cursor: '',
        next: '',
        prev: ''
    });

    useEffect(() => {
        getHistory(handleSetData, data, cookies.token);
    }, [data.current]);

    const handleSetData = (val) => {
        setData({
            ...data,
            pages: val.pages,
            current: val.current,
            // prev: val.prev,
            // next: val.next
        });
        setTrans(val.trans);
    }

    const handleStartTrial = (val) => {
        checkout({amount: val.toString(), currency: 'USD'}, cookies.token);
    }

    const handleBuySubscription = (val) => {
        checkout_subscription({amount: val.toString(), currency: 'USD'}, cookies.token);
    }

    const handleBuyCredit = (val) => {
        checkout_credit({amount: val.toString(), currency: 'USD'}, cookies.token);
    }

    const handleSearch = () => {
        getHistory(handleSetData, data, cookies.token);
    }

    const handleNextClick = () => {
        if (data.current < data.pages)
            setData({
                ...data,
                current: data.current + 1,
                // cursor: data.next
            })
    }

    const handlePrevClick = () => {
        if (data.current > 1)
            setData({
                ...data,
                current: data.current - 1,
                // cursor: data.prev
            })
    }

    const paymentStatus = (val) => {
        switch(val) {
            case "paid":
                return "green";
            case "paid_over":
                return "green";
            case "wrong_amount":
                return "yellow";
            case "process":
                return "yellow";
            case "confirm_check":
                return "green";
            case "wrong_amount_waiting":
                return "yellow";
            case "check":
                return "yellow";
            case "fail":
                return "red";
            case "cancel":
                return "red";
            case "system_fail":
                return "red";
            case "refund_process":
                return "yellow";
            case "refund_fail":
                return "red";
            case "refund_paid":
                return "green";
            case "locked":
                return "red";
            default:
                break;
        }
    }

    const paymentStatusName = (val) => {
        switch(val) {
            case "paid":
                return "Paid";
            case "paid_over":
                return "Paid Over";
            case "wrong_amount":
                return "Wrong Amount";
            case "process":
                return "Process";
            case "confirm_check":
                return "Confirm Check";
            case "wrong_amount_waiting":
                return "Wrong Amount Waiting";
            case "check":
                return "Check";
            case "fail":
                return "Fail";
            case "cancel":
                return "Cancel";
            case "system_fail":
                return "System Fail";
            case "refund_process":
                return "Refund Process";
            case "refund_fail":
                return "Refund Fail";
            case "refund_paid":
                return "Refund Paid";
            case "locked":
                return "Locked";
            default:
                break;
        }
    }

    return (
        <div className='Container'>
            <Box w={"100%"} py={10}>
                <VStack spacing={2} alignItems={'flex-end'} px={5}>
                    <Link to={'/'}>
                        <Button fontSize={30} variant={'ghost'}><TiArrowBack /></Button>
                    </Link>
                </VStack>
                <VStack spacing={2} textAlign="center">
                    <Heading as="h1" fontSize="4xl" color="white">
                        {t('payment.token')}
                    </Heading>
                    {/* <Text fontSize="lg" color={'white'}>
                        Start with 14-day free trial. No credit card needed. Cancel at
                        anytime.
                    </Text> */}
                </VStack>
                <Stack
                    width={"100%"}
                    // direction={{ base: 'column', lg: 'row' }}
                    textAlign="center"
                    justify="center"
                    spacing={{ base: 4, lg: 10 }}
                    py={10}>
                        <Wrap spacing={{ base: 4, lg: 10 }} justify="center">
                            <PriceWrapper>
                                <Box py={4} px={12}>
                                    <HStack justifyContent="center">
                                        <Text fontSize="3xl" fontWeight="600" color="white">
                                            $
                                        </Text>
                                        <Text fontSize="5xl" fontWeight="900" color="white">
                                            5.99
                                        </Text>
                                    </HStack>
                                </Box>
                                <VStack
                                    bg={useColorModeValue('gray.50', 'gray.700')}
                                    py={4}
                                    borderBottomRadius={'xl'}>
                                    <List spacing={3} textAlign="start" px={12}>
                                    <ListItem>
                                        <ListIcon as={FaCheckCircle} color="green.500" />
                                        +500,000
                                    </ListItem>
                                    </List>
                                    <Box w="80%" pt={7}>
                                    <Button w="full" colorScheme="red" variant="outline" onClick={() => handleStartTrial(5.99)}>
                                        {t('payment.buy')}
                                    </Button>
                                    </Box>
                                </VStack>
                            </PriceWrapper>
                            <PriceWrapper>
                                <Box py={4} px={12}>
                                    <HStack justifyContent="center">
                                        <Text fontSize="3xl" fontWeight="600" color="white">
                                            $
                                        </Text>
                                        <Text fontSize="5xl" fontWeight="900" color="white">
                                            8.99
                                        </Text>
                                    </HStack>
                                </Box>
                                <VStack
                                    bg={useColorModeValue('gray.50', 'gray.700')}
                                    py={4}
                                    borderBottomRadius={'xl'}>
                                    <List spacing={3} textAlign="start" px={12}>
                                    <ListItem>
                                        <ListIcon as={FaCheckCircle} color="green.500" />
                                        +1,000,000
                                    </ListItem>
                                    </List>
                                    <Box w="80%" pt={7}>
                                    <Button w="full" colorScheme="red" variant="outline" onClick={() => handleStartTrial(8.99)}>
                                        {t('payment.buy')}
                                    </Button>
                                    </Box>
                                </VStack>
                            </PriceWrapper>

                            <PriceWrapper>
                                <Box position="relative">
                                    <Box
                                    position="absolute"
                                    top="-16px"
                                    left="50%"
                                    style={{ transform: 'translate(-50%)' }}>
                                    <Text
                                        textTransform="uppercase"
                                        bg={useColorModeValue('red.300', 'red.700')}
                                        px={3}
                                        py={1}
                                        color={useColorModeValue('white', 'white')}
                                        fontSize="sm"
                                        fontWeight="600"
                                        rounded="xl">
                                        {t('payment.popular')}
                                    </Text>
                                    </Box>
                                    <Box py={4} px={12}>
                                        <HStack justifyContent="center">
                                            <Text fontSize="3xl" fontWeight="600" color="white">
                                            $
                                            </Text>
                                            <Text fontSize="5xl" fontWeight="900" color="white">
                                            19.99
                                            </Text>
                                        </HStack>
                                    </Box>
                                    <VStack
                                    bg={useColorModeValue('gray.50', 'gray.700')}
                                    py={4}
                                    borderBottomRadius={'xl'}>
                                    <List spacing={3} textAlign="start" px={12}>
                                        <ListItem>
                                        <ListIcon as={FaCheckCircle} color="green.500" />
                                        +3,000,000
                                        </ListItem>
                                    </List>
                                    <Box w="80%" pt={7}>
                                        <Button w="full" colorScheme="red" onClick={() => handleStartTrial(19.99)}>
                                            {t('payment.buy')}
                                        </Button>
                                    </Box>
                                    </VStack>
                                </Box>
                            </PriceWrapper>

                            <PriceWrapper>
                                <Box py={4} px={12}>
                                    <HStack justifyContent="center">
                                        <Text fontSize="3xl" fontWeight="600" color="white">
                                            $
                                        </Text>
                                        <Text fontSize="5xl" fontWeight="900" color="white">
                                            59.99
                                        </Text>
                                    </HStack>
                                </Box>
                                <VStack
                                    bg={useColorModeValue('gray.50', 'gray.700')}
                                    py={4}
                                    borderBottomRadius={'xl'}>
                                    <List spacing={3} textAlign="start" px={12}>
                                    <ListItem>
                                        <ListIcon as={FaCheckCircle} color="green.500" />
                                        +10,000,000
                                    </ListItem>
                                    </List>
                                    <Box w="80%" pt={7}>
                                    <Button w="full" colorScheme="red" variant="outline" onClick={() => handleStartTrial(59.99)}>
                                        {t('payment.buy')}
                                    </Button>
                                    </Box>
                                </VStack>
                            </PriceWrapper>
                            <PriceWrapper>
                                <Box py={4} px={12}>
                                    <HStack justifyContent="center">
                                        <Text fontSize="3xl" fontWeight="600" color="white">
                                            $
                                        </Text>
                                        <Text fontSize="5xl" fontWeight="900" color="white">
                                            249.99
                                        </Text>
                                    </HStack>
                                </Box>
                                <VStack
                                    bg={useColorModeValue('gray.50', 'gray.700')}
                                    py={4}
                                    borderBottomRadius={'xl'}>
                                    <List spacing={3} textAlign="start" px={12}>
                                    <ListItem>
                                        <ListIcon as={FaCheckCircle} color="green.500" />
                                        +50,000,000
                                    </ListItem>
                                    </List>
                                    <Box w="80%" pt={7}>
                                    <Button w="full" colorScheme="red" variant="outline" onClick={() => handleStartTrial(249.99)}>
                                        {t('payment.buy')}
                                    </Button>
                                    </Box>
                                </VStack>
                            </PriceWrapper>
                        </Wrap>
                </Stack>
                <VStack spacing={2} textAlign="center">
                    <Heading as="h1" fontSize="4xl" color="white">
                        {t('payment.subscription')}
                    </Heading>
                    {/* <Text fontSize="lg" color={'white'}>
                        Start with 14-day free trial. No credit card needed. Cancel at
                        anytime.
                    </Text> */}
                </VStack>
                <Stack
                    width={"100%"}
                    direction={{ base: 'column', md: 'row' }}
                    textAlign="center"
                    justify="center"
                    spacing={{ base: 4, lg: 10 }}
                    py={10}>
                    <PriceWrapper>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl" color="white">
                                {t('payment.weekly')}
                            </Text>
                            <HStack justifyContent="center">
                                <Text fontSize="3xl" fontWeight="600" color="white">
                                    $
                                </Text>
                                <Text fontSize="5xl" fontWeight="900" color="white">
                                    1.99
                                </Text>
                            </HStack>
                            <Text color={'transparent'}>.</Text>
                        </Box>
                        <VStack
                            bg={useColorModeValue('gray.50', 'gray.700')}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                100,000&nbsp;{t('payment.tpd')}
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                ⚡️&nbsp;{t('payment.instant')}
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                {t('payment.promode')}
                            </ListItem>
                            </List>
                            <Box w="80%" pt={7}>
                            <Button w="full" colorScheme="red" variant="outline" onClick={() => handleBuySubscription(1.99)}>
                                {t('payment.buy')}
                            </Button>
                            </Box>
                        </VStack>
                    </PriceWrapper>

                    <PriceWrapper>
                        <Box position="relative">
                            <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: 'translate(-50%)' }}>
                            </Box>
                            <Box py={4} px={12}>
                                <Text fontWeight="500" fontSize="2xl" color="white">
                                    {t('payment.monthly')}
                                </Text>
                                <HStack justifyContent="center">
                                    <Text fontSize="3xl" fontWeight="600" color="white">
                                    $
                                    </Text>
                                    <Text fontSize="5xl" fontWeight="900" color="white">
                                    1.35
                                    </Text>
                                </HStack>
                                <Text color={'white'}>/ {t('payment.week')} (-25%)</Text>
                            </Box>
                            <VStack
                            bg={useColorModeValue('gray.50', 'gray.700')}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    100,000&nbsp;{t('payment.tpd')}
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    ⚡️&nbsp;{t('payment.instant')}
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    {t('payment.promode')}
                                </ListItem>
                            </List>
                            <Box w="80%" pt={7}>
                                <Button w="full" colorScheme="red" onClick={() => handleBuySubscription(5.4)}>
                                    {t('payment.buy')}
                                </Button>
                            </Box>
                            </VStack>
                        </Box>
                    </PriceWrapper>
                    
                    <PriceWrapper>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl" color="white">
                                {t('payment.yearly')}
                            </Text>
                            <HStack justifyContent="center">
                                <Text fontSize="3xl" fontWeight="600" color="white">
                                    $
                                </Text>
                                <Text fontSize="5xl" fontWeight="900" color="white">
                                    1.15
                                </Text>
                            </HStack>
                            <Text color={'white'}>/ {t('payment.week')} (-45%)</Text>
                        </Box>
                        <VStack
                            bg={useColorModeValue('gray.50', 'gray.700')}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                100,000&nbsp;{t('payment.tpd')}
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                ⚡️&nbsp;{t('payment.instant')}
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                {t('payment.promode')}
                            </ListItem>
                            </List>
                            <Box w="80%" pt={7}>
                            <Button w="full" colorScheme="red" variant="outline" onClick={() => handleBuySubscription(59.8)}>
                                {t('payment.buy')}
                            </Button>
                            </Box>
                        </VStack>
                    </PriceWrapper>
                </Stack>
                <VStack spacing={2} textAlign="center">
                    <Heading as="h1" fontSize="4xl" color="white">
                        {t('payment.transactions')}
                    </Heading>
                </VStack>
                <VStack
                    display={"block"}
                    width={"100%"}
                    direction={{ base: 'column', md: 'row' }}
                    textAlign="center"
                    justify="center"
                    spacing={{ base: 4, lg: 10 }}
                    py={10}
                    px={5}
                    overflowX={'auto'}
                    margin={"auto"}
                >
                    {/* <Box w={'100%'} justifyContent={'end'} display={'flex'}>
                        <InputGroup w={'25%'} borderRadius={5} size="sm">
                            <InputLeftElement
                                pointerEvents="none"
                                children={<Search2Icon color="gray.600" />}
                            />
                            <Input type="text" color={'white'} placeholder="e.g. 698e180772565f7b4231b045" border="1px solid #949494" value={data.search} onKeyDown={e => {if (e.key == 'Enter') handleSearch()}} onChange={e => setData({...data, search: e.target.value})} />
                            <InputRightAddon
                                p={0}
                                border="none"
                            >
                            <Button size="sm" borderLeftRadius={0} borderRightRadius={3.3} border="1px solid #949494" onClick={handleSearch}>
                                Search
                            </Button>
                            </InputRightAddon>
                        </InputGroup>
                    </Box> */}
                    <Table>
                        <Thead>
                            <Tr>
                                <Th className='cell'>{t('payment.orderid')}</Th>
                                <Th className='cell'>{t('payment.status')}</Th>
                                <Th className='cell'>{t('payment.currency')}</Th>
                                <Th className='cell' isNumeric>{t('payment.amount')}</Th>
                                <Th className='cell'>{t('payment.payer_currency')}</Th>
                                <Th className='cell' isNumeric>{t('payment.payer_amount')}</Th>
                                <Th className='cell'>{t('payment.network')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                trans.map(item => {
                                    return <Tr key={item.order_id}>
                                        <Td className='cell'>{item.order_id}</Td>
                                        <Td className='cell'>
                                            <Tag colorScheme={paymentStatus(item.status)} variant={'outline'}>{paymentStatusName(item.status)}</Tag>
                                        </Td>
                                        <Td className='cell'>{item.currency}</Td>
                                        <Td className='cell' isNumeric>{item.amount}</Td>
                                        <Td className='cell'>{item.payer_currency}</Td>
                                        <Td className='cell' isNumeric>{item.payment_amount}</Td>
                                        <Td className='cell'>{item.network}</Td>
                                    </Tr>    
                                })
                            }                            
                        </Tbody>
                    </Table>
                </VStack>
                {trans.length != 0 && <Flex alignItems={'center'} pt={5} justifyContent={'center'}>
                    <Button mr={3} onClick={handlePrevClick}><GrPrevious /></Button>
                    {/* <Input type='number' min={1} w={10} color={'white'} value={data.current} onKeyDown={e => {if (e.key == 'Enter') handleSearch()}} onChange={e => {if (e.target.value <= data.pages && e.target.value > 0) setData({...data, current: e.target.value})}} /> */}
                    <Text color={'white'} fontSize={'xl'}>{data.current}&nbsp;/&nbsp;{data.pages}</Text>
                    <Button ml={3} onClick={handleNextClick}><GrNext /></Button>
                </Flex>}
                {trans.length == 0 && <Center>
                    <Text color={'white'} display={'flex'} alignItems={'center'} fontSize={'lg'}>
                        <HiOutlineInboxStack /> &nbsp; {t('payment.empty')}
                    </Text>
                </Center>}
            </Box>
        </div>
    )
}
