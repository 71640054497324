import React, {useEffect, useState, useContext} from 'react';
import {
    Modal,
    ModalBody,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
    Divider,
    Image,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next'

export const ImageModal = ({isOpen, onClose, src}) => {
    const { t } = useTranslation('common');
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={'xl'}
        >
            <ModalOverlay />
            <ModalContent minH={500}>
                <ModalHeader>{t('image_generate')}</ModalHeader>
                <ModalCloseButton />
                <Divider />
                <ModalBody>
                    <Image w={'100%'} h={'100%'} src={src} />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}