import React, {useState, useEffect, useContext } from 'react'
import {
    VStack,
    Flex,
    Box,
    Text,
    Divider,
    Image,
    Textarea,
    Link,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    ModalCloseButton,
    Input,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,

} from '@chakra-ui/react'
import {
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { AiOutlineMore } from "react-icons/ai";
import { IoIosImages } from "react-icons/io";
import { FiSave } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Message from './Message';
import {
    MessageInput,
    TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
// import "primereact/resources/themes/lara-dark-teal/theme.css";

import { FileUpload } from 'primereact/fileupload'
import 'react-image-upload/dist/index.css'
import { useCookies } from 'react-cookie';
import { createPrompt } from '../actions/prompts';
import { API_URL, SERVER_URL } from '../util/constant';
import { saveChatHistory } from '../actions/chat_history';
// import { getRealTimeData } from '../actions/oracle';
import axios from 'axios';
import { AppContext } from '../provider/AppProvider';
import { CreateAlert } from './CreateAlert';
import { consumeToken, getInfo } from '../actions/auth';
import { setCookie } from 'react-use-cookie';
import { ImageModal } from './ImageModal';
import { generateImage } from '../actions/image';
import { useTranslation } from 'react-i18next'

export default function Content(props) {
    console.log(props.model)
    const { t, i18n } = useTranslation("common");
    const toast = useToast();
    const { setModes, setFlag } = useContext(AppContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isCreateAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const { isOpen: isChatOpen, onOpen: onChatOpen, onClose: onChatClose } = useDisclosure();
    const { isOpen: isImageOpen, onOpen: onImageOpen, onClose: onImageClose} = useDisclosure();
    const [cookies] = useCookies(['token', 'model', 'amount']);
    const API_KEY = process.env.REACT_APP_API_KEY;
    const ORACLE_API_KEY = process.env.REACT_APP_ORACLE_API_KEY;
    const BITTENSOR_API_KEY = process.env.REACT_APP_BITTENSOR_API_KEY;
    const [isTyping, setIsTyping] = useState(false);
    const [mode, setMode] = useState('');
    const [name, setName] = useState('');
    const [img, setImg] = useState('');
    const [src, setSrc] = useState('');
    const [messages, setMessages] = useState([
        {
            message: `Hello! Ask me anything!`,
            sentTime: new Date().toLocaleTimeString(),
            sentDate: new Date().toLocaleDateString(),
            sender: "ChatGPT",
        },
    ]);
    const [chatbot, setChatBot] = useState({
        name: '',
        content: '',
        img: ''
    });

    useEffect(() => {
        if (props.model.name !== '') {
            if (messages.length > 1 && (props.model.messages.length != messages.length || props.model.name != mode))
                handleSaveChatHistory(mode+' '+ new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString());
            setMode(props.model.name);
            setMessages([{
                message: `Hello, I am ${props.model.name}! Ask me anything!`,
                sentTime: new Date().toLocaleTimeString(),
                sentDate: new Date().toLocaleDateString(),
                sender: "ChatGPT",
            }])
        }
    }, [props.model.name]);

    useEffect(() => {
        if (props.model.messages.length != 0) {
            setMessages(props.model.messages);
        }
    }, [props.model.messages]);

    useEffect(() => {
        setImg(props.model.img);
    }, [props.model.img]);

    useEffect(() => {
        const element = document.getElementById('messages');
        element.scrollTop = element.scrollHeight;
    }, [messages]);

    const handleSetToken = (val) => {
        setCookie('amount', val, {path: '/'});
    }

    const handleNext = () => {
        setFlag(false);
        setIsTyping(false);
        onImageOpen();
    }

    const handleSendRequest = async (message) => {
        // const amount = Math.ceil(message.length / 4);
        // if (cookies.amount < amount) {
        //     return toast({
        //         title: 'Error',
        //         description: "You don't have enough token.",
        //         status: 'error',
        //         duration: '3000',
        //         isClosable: true,
        //         position: 'top-right'
        //     });
        // }
        if (isTyping) return;

        const newMessage = {
            message,
            direction: 'outgoing',
            sender: "user",
            sentTime: new Date().toLocaleTimeString(),
            sentDate: new Date().toLocaleDateString(),
        };
    
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        try {
            const res = await axios.post(`${API_URL}/user/info`, {message} ,{
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });
            setCookie('amount', res.data.token, {path: '/'});
            i18n.changeLanguage(res.data.language);
            if (!res.data.available) {
                return toast({
                    title: 'Error',
                    description: "You don't have enough token.",
                    status: 'error',
                    duration: '3000',
                    isClosable: true,
                    position: 'top-right'
                });
            }
            setIsTyping(true);
            
            if (props.model.name == 'DALLE-3-Image Generation [PRO]') {
                setFlag(true)
                generateImage(cookies.token, {message}, setSrc, handleNext);
                return ;
            }
    
            try {
                const response = await processMessageToChatGPT([...messages, newMessage]);
                const content = response.choices[0]?.message?.content;
                if (content) {
                    const chatGPTResponse = {
                        message: content,
                        sender: "ChatGPT",
                        sentTime: new Date().toLocaleTimeString(),
                        sentDate: new Date().toLocaleDateString(),
                    };
                    setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
                    consumeToken(cookies.token, {message, content}, handleSetToken);
                }
            } catch (error) {
                console.error("Error processing message:", error);
            } finally {
                setIsTyping(false);
            }
        }
        catch (e) {
            console.log(e);
        }
    };
    async function processMessageToChatGPT(chatMessages) {
        if (cookies.model != "Oracle") {
            const apiMessages = chatMessages.map((messageObject) => {
                const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
                return { role, content: messageObject.message };
            });

            let url, api_key, model;
            switch(cookies.model) {
                case "ChatGPT-3.5":
                    model = "gpt-3.5-turbo";
                    url = "https://api.openai.com/v1/chat/completions";
                    api_key = "Bearer " + API_KEY;
                    break;
                case "ChatGPT-4":
                    model = "gpt-4-turbo";
                    url = "https://api.openai.com/v1/chat/completions";
                    api_key = "Bearer " + API_KEY;
                    break;
                case "ChatGPT-4o":
                    model = "gpt-4o";
                    url = "https://api.openai.com/v1/chat/completions";
                    api_key = "Bearer " + API_KEY;
                    break;
                case "Gemini":
                    model = "google/gemini-pro";
                    url = "https://openrouter.ai/api/v1/chat/completions";
                    api_key = "Bearer " + ORACLE_API_KEY;
                    break;
                case "CodeLlama":
                    model = "codellama/codellama-70b-instruct";
                    url = "https://openrouter.ai/api/v1/chat/completions";
                    api_key = "Bearer " + ORACLE_API_KEY;
                    break;
                case "Bittensor":
                    url="https://api.corcel.io/v1/text/cortext/chat";
                    model="cortext-ultra";
                    api_key = BITTENSOR_API_KEY;
                    const response = await axios.post(`${API_URL}/bittensor`, {
                        "model": "cortext-ultra",
                        "messages": [
                            { role: "system", content: chatbot.content == '' ? props.model.content : chatbot.content },
                            ...apiMessages,
                        ],
                        "temperature": 0.8,
                        "stream": false,
                        "top_p": 1,
                        "max_tokens": 4096
                    });
                    return {choices: [{
                        message: {
                            content: response.data[0].choices[0].delta.content
                        }
                    }]};
                default:
                    model = "gpt-3.5-turbo";
                    url = "https://api.openai.com/v1/chat/completions";
                    api_key = "Bearer " + API_KEY;
                    break;
            }
    
            // const apiRequestBody = {
            //     "model": model,
            //     "messages": [
            //         { role: "system", content: chatbot.content == '' ? props.model.content : chatbot.content },
            //         ...apiMessages,
            //     ],
            //     "temperature": 0.8,
            //     "stream": false,
            //     "top_p": 1,
            //     "max_tokens": 4096
            // };

            // const response = await fetch(url, {
            //     method: "POST",
            //     headers: {
            //         "Authorization": api_key,
            //         "Content-Type": "application/json",
            //         "Accept": 'application/json'
            //     },
            //     body: JSON.stringify(apiRequestBody),
            // });

            const apiRequestBody = {
                model: model,
                messages: [
                    { role: "system", content: chatbot.content == '' ? props.model.content : chatbot.content },
                    ...apiMessages,
                ],
                temperature: parseFloat(props.model.temperature),
                stream: false,
                top_p: 1,
                max_tokens: parseInt(props.model.max_tokens)
            };
    
            const response = await axios.post(`${API_URL}/get/chat`, apiRequestBody);
    
            return response.data;
        }
        else {
            // const response = await fetch(`${API_URL}/oracle/data`, {
            //     method: "POST",
            //     headers: {
            //         "Authorization": "Bearer " + cookies.token
            //     },
            //     body: JSON.stringify({message: chatMessages[chatMessages.length - 1].message})
            // });

            const response = await axios.post(`${API_URL}/oracle/data`, {message: chatMessages[chatMessages.length - 1].message}, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });
            const res = await response.data;
            return {
                choices: [{
                    index: 0,
                    message: {
                        role: "assistant",
                        content: res.split('Final answer:')[1]
                    }
                }]
            }
        }
    }

    const handleGeneratePrompt = () => {
        if (chatbot.content === '')
            return toast({
                title: 'Error',
                description: 'You must fill out the prompt.',
                status: 'error',
                duration: '3000',
                isClosable: true,
                position: 'top-right'
            });

        onOpen();
    }

    const handleFileUpload = (e) => [
        setChatBot({
            ...chatbot,
            img: e.files[0]
        })
    ]
    const handleSavePrompts = () => {
        if (chatbot.name === '')
            return toast({
                title: 'Error',
                description: 'Name is required.',
                status: 'error',
                duration: '3000',
                isClosable: true,
                position: 'top-right'
            });
        if (chatbot.img === '') {
            return toast({
                title: 'Error',
                description: 'Image is required.',
                status: 'error',
                duration: '3000',
                isClosable: true,
                position: 'top-right'
            });
        }
        else {
            onOpenAlert();
            // const formData = new FormData();
            // formData.append('file', chatbot.img);
            // formData.append('data', JSON.stringify(chatbot));
            // createPrompt(cookies.token, formData, setModes);
            // onClose();
            // setChatBot({name: '', content: '', img: ''});
        }
    }

    const handleCreateBot = () => {
        const formData = new FormData();
        formData.append('file', chatbot.img);
        formData.append('data', JSON.stringify(chatbot));
        setFlag(true);
        createPrompt(cookies.token, formData, setModes, setFlag);
        onClose();
        setChatBot({name: '', content: '', img: ''});
        onCloseAlert();
    }

    const handleSaveChatHistory = (name) => {
        saveChatHistory(cookies.token, {messages, mode, name, img});
        onChatClose();
        handleRemoveChatHistory()
    }

    const handleRemoveChatHistory = () => {
        let newMessages = [messages[0]]
        setMessages(newMessages);
    }

    const handleDeleteMessage = (message) => {
        const idx = messages.findIndex(item => item.message === message);
        if (idx < 0) return;
        const newMessages = messages.filter((item, index) => index != idx && item);
        setMessages(newMessages);
    }

    const handleQuestionClick = (ques) => {
        handleSendRequest(ques);
    }

    return (
        <VStack w={"100%"} h={"100%"} px={{md: 10}} pt={6}>
            <VStack w={"100%"} h={"78vh"} maxH={"78vh"} backgroundColor={"#15151C"} borderRadius={"20px"} px={10} py={6} my={3}>
                <Flex w={'100%'} justifyContent={'space-between'} pb={3}>
                    <Flex alignItems={'center'}>
                        <Box w={"61px"} h={"61px"} display={'flex'} border={"1px solid #5DD8BE"} borderRadius={"50px"}>
                            <Image src={img === '' ? SERVER_URL + '/public/img/6.webp' : SERVER_URL + img} margin={'auto'} w={"50px"} h={"50px"} borderRadius={'50px'} />
                        </Box>
                        <Text pl={5} color={'white'} fontSize={"24px"}>{t('content.logo')} {props.model.name}</Text>
                    </Flex>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={<AiOutlineMore fontSize={30} color='white' />}
                            variant='ghost'
                        />
                        <MenuList>
                            <MenuItem icon={<FiSave fontSize={18} />} onClick={onChatOpen}>
                                {t('content.save')}
                            </MenuItem>
                            <MenuItem icon={<RiDeleteBin6Line fontSize={18} />} onClick={handleRemoveChatHistory}>
                                {t('content.delete')}
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    {/* <AiOutlineMore fontSize={30} color='white' style={{marginRight: -15}}/> */}
                </Flex>
                <Divider color={"#5D5D5D"} />
                <VStack id='messages' w={"100%"} h={"65vh"} maxH={"65vh"} mt={2} overflow={'auto'} overflowX={'hidden'} pr={2}>
                    {
                        messages.map((message, index) => {
                            return <Message
                                        key={'msg_'+index}
                                        id={'msg_'+index}
                                        time={message.sentTime}
                                        date={message.sentDate}
                                        mine={message.sender === 'user' ? true : false}
                                        text={message.message}
                                        onDelete={() => handleDeleteMessage(message.message)}
                                    />
                        })
                    }
                </VStack>
                <VStack w={'100%'} position={'relative'}>
                    {isTyping && <TypingIndicator className='typing' content={'AI is typing...'} />}
                    <MessageInput className='msg' sendButton={false} attachButton={false} placeholder={t('content.send')} onSend={handleSendRequest}/>
                    {
                        props.model.enable_ques && <Flex justifyContent={'space-between'} w={'full'}>
                            {
                                props.model.questions.map((item, index) => {
                                    return <Button w={'31%'} borderRadius={50} bgColor={'#282c34'} color={'white'} justifyContent={'start'} pr={2} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} key={'question_'+index} onClick={() => handleQuestionClick(item)}>{item}</Button>
                                })
                            }
                        </Flex> 
                    }
                </VStack>
            </VStack>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('content.create_bot.title')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>
                            <Flex w={'100%'} alignItems={'center'}>
                                <Text w={'28%'}>{t('content.create_bot.name')}:</Text>
                                <Input placeholder={t('content.create_bot.placeholder')} value={chatbot.name} onChange={e => setChatBot({...chatbot, name: e.target.value})} />
                            </Flex>
                            <Flex w={'100%'} alignItems={'center'}>
                                <Text pr={3}>{t('content.create_bot.image')}:</Text>
                                <FileUpload mode="basic" chooseLabel={t('content.create_bot.choose')} name="demo[]" accept="image/*" maxFileSize={10000000} onSelect={e => handleFileUpload(e)}  />
                            </Flex>
                            <Flex w={'100%'} justifyContent={'end'} mb={3} pt={'16px'}>
                                <Button variant={'primary'} background='teal' color={'white'} onClick={handleSavePrompts}>{t('content.create_bot.save')}</Button>
                                <Button ml={3} onClick={onClose}>{t('content.create_bot.cancel')}</Button>
                            </Flex>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={isChatOpen}
                onClose={onChatClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('content.save')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input placeholder={t('content.name')} value={name} onChange={e => setName(e.target.value)}/>
                        <Flex w={'100%'} pt={3} justifyContent={'end'} mb={3}>
                            <Button colorScheme='teal' onClick={() => handleSaveChatHistory(name)}>{t('content.save_chat')}</Button>
                            <Button ml={3} onClick={onChatClose}>{t('content.cancel_chat')}</Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <ImageModal isOpen={isImageOpen} onClose={onImageClose} src={src} />
            <CreateAlert isOpen={isCreateAlert} onClose={onCloseAlert} header={t('content.create_bot.title')} msg={t('content.create_bot.alert')} clickOk={handleCreateBot} />
        </VStack>
    )
}
