import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { I18nextProvider } from 'react-i18next';
import i18next from "i18next";

import common_ar from './translations/ar/common.json';
import common_cn from './translations/cn/common.json';
import common_de from './translations/de/common.json';
import common_en from './translations/en/common.json';
import common_es from './translations/es/common.json';
import common_fr from './translations/fr/common.json';
import common_id from './translations/id/common.json';
import common_in from './translations/in/common.json';
import common_ir from './translations/ir/common.json';
import common_it from './translations/it/common.json';
import common_jp from './translations/jp/common.json';
import common_pt from './translations/pt/common.json';
import common_ru from './translations/ru/common.json';
import common_tr from './translations/tr/common.json';

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    de: {
      common: common_de
    },
    ar: {
      common: common_ar
    },
    cn: {
      common: common_cn
    },
    es: {
      common: common_es
    },
    fr: {
      common: common_fr
    },
    id: {
      common: common_id
    },
    in: {
      common: common_in
    },
    ir: {
      common: common_ir
    },
    it: {
      common: common_it
    },
    jp: {
      common: common_jp
    },
    pt: {
      common: common_pt
    },
    ru: {
      common: common_ru
    },
    tr: {
      common: common_tr
    }
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false
  },
})

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode='dark' />
      <GoogleOAuthProvider clientId='626967374853-tpfmig8k8jfbt7e7177jci7e8nlvq33j.apps.googleusercontent.com'>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </GoogleOAuthProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
