import {
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Button
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const CreateAlert = ({header, msg, onClose, isOpen, clickOk}) => {
    const { t } = useTranslation("common");

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {header}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {msg}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                        <Button colorScheme='teal' onClick={clickOk}>
                            {t('content.create_bot.create')}
                        </Button>
                        <Button onClick={onClose} ml={3}>
                            {t('content.cancel_chat')}
                        </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}