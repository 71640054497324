import React, { useEffect } from 'react'
import {
    Flex,
    Text,
    Box,
    useClipboard
} from '@chakra-ui/react'
import { FaRegCopy } from "react-icons/fa";
import { FiCheckSquare } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";

export default function Message(props) {
    const { onCopy, value, setValue, hasCopied } = useClipboard('');
    useEffect(() => {
        if (props.text !== '') {
            const element = document.getElementById(props.id);
            const msg = props.text.replace(/\n/g, '<br>');
            element.innerHTML = msg;
        }
    // eslint-disable-next-line
    }, [props.text]);

    const handleCopyClick = () => {
        const msg = props.text;
        setValue(msg);
        onCopy();
    }

    const handleDelete = () => {
        props.onDelete();
    }

    return (
        <Flex w={'100%'} justifyContent={props.mine ? 'end' : 'start'}>
            {props.mine && <Text fontSize={'13px'} color={'white'} opacity={0.3} mr={2}>{props.date === new Date().toLocaleDateString() ? props.time : props.date}</Text>}
            <Box position={'relative'} paddingBottom={'1.5rem'}>
                <Text
                    id={props.id}
                    maxW={'45vw'}
                    textAlign={'left'}
                    fontSize={'17px'}
                    color={'white'}
                    p={2}
                    borderRadius={'12px'}
                    background={props.mine ? 'linear-gradient(106deg, #5DD8BE 0%, #B776FF 100%)' : '#6A6A88'}
                >
                </Text>
                {!hasCopied && <FaRegCopy style={{position: 'absolute', bottom: '0', right: '2rem', cursor: 'pointer'}} color='white' onClick={handleCopyClick} />}
                {hasCopied && <FiCheckSquare style={{position: 'absolute', bottom: '0', right: '2rem'}} color='white' />}
                <RiDeleteBinLine style={{position: 'absolute', bottom: '0', right: '0.3rem', cursor: 'pointer'}} color='white' onClick={handleDelete}/>
            </Box>
            {!props.mine && <Text fontSize={'13px'} color={'white'} opacity={0.3} ml={2}>{props.time}</Text>}
        </Flex>
    )
}
