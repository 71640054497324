import axios from 'axios';
import { API_URL } from '../util/constant';

export const saveChatHistory = (cookie, data) => {
    axios.post(`${API_URL}/chat/save`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            console.log(res.data);
        })
        .catch(err => console.log(err));
}

export const deleteChatHistory = (cookie, data) => {
    axios.post(`${API_URL}/chat/delete`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {

        })
        .catch(err => console.log(err));
}

export const getChatList = (cookie, setData) => {
    axios.get(`${API_URL}/chat/list`, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            // console.log(res.data);
            setData(res.data);
        })
        .catch(err => console.log(err));
}

export const getChatContent = (cookie, data) => {

}