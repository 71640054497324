import axios from 'axios';
import { API_URL } from '../util/constant';

export const getPrompts = (cookie, setData, setFlag) => {

    axios.get(`${API_URL}/get/prompts`, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            const result = [...res.data.filter((mode)=> mode.user), ...res.data.filter((mode)=> !mode.user)];
            setData(result);
            setFlag(false);
        })
        .catch(err => console.log(err));
}

export const createPrompt = (cookie, data, setData, setFlag) => {
    axios.post(`${API_URL}/set/prompts`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            getPrompts(cookie, setData, setFlag);
        })
        .catch(err => console.log(err));
}

export const getMineBots = (cookie, setData) => {
    axios.get(`${API_URL}/get/mine/prompts`, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            setData(res.data);
        })
        .catch(err => console.log(err));
}

export const deleteMineBot = (cookie, data, setData, setModes, setFlag) => {
    axios.post(`${API_URL}/delete/mine/prompt`, data, {
        headers: {
            Authorization: `Bearer ${cookie}`
        }
    })
        .then(res => {
            getMineBots(cookie, setData);
            getPrompts(cookie, setModes, setFlag);
        })
        .catch(err => console.log(err));
}