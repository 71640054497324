export const chains_list = [
    ["Ethereum", "eth"],
    ["Arbitrum", "arbitrum"],
    ["Binance Smart Chain", "bsc"],
    ["Polygon", "polygon"],
    ["Fantom", "fantom"],
    ["Avalanche", "avalanche"],
    ["Core DAO", "core"],
    ["zkSync", "zksync"],
    ["PolygonZkEVM", "polygonzkevm"],
    ["Loop", "loop"],
    ["Kava", "kava"],
    ["Metis", "metis"],
    ["Astar", "astar"],
    ["Oasis", "oasis"],
    ["IoTeX", "iotex"],
    ["Conflux", "conflux"],
    ["Canto", "canto"],
    ["Energi", "energi"],
    ["Velas", "velas"],
    ["Grove", "grove"],
    ["Pulse Chain", "pulse"],
    ["BESC", "besc"],
    ["Linea", "linea"],
    ["Base", "base"],
    ["Shibarium", "shibarium"],
    ["opBNB", "opbnb"],
    ["Bitrock", "bitrock"],
    ["Optimism", "optimism"],
    ["Mantle", "mantle"],
    ["LightLink", "lightlink"],
    ["Klaytn", "klaytn"],
    ["Solana", "solana"],
    ["Injective", "injective"],
    ["Radix", "radix"],
    ["Sui", "sui"],
    ["Manta Pacific", "manta"],
    ["ZetaChain", "zeta"],
];

export const own_renounced = "Yes, ownership has been renounced, the owner doesn't have control over the contract functions, therefore, for example, cannot change fees.";
export const not_own_renounced = "No, ownership hasn't been renounced, the owner can interact with the contract and change its functions.";
export const black_func_renounced = "Yes, the contract is unable to add wallets to the blacklist after renouncing.";
export const not_black_func_renounced = "No, the contract is able to add wallets to the blacklist after renouncing.";
export const can_blacklist = "Adding new wallets to the blacklist is possible, be very cautious with this contract, especially if it has just been launched.";
export const not_can_blacklist = "Blacklist addition is permanently disabled, no new addresses can be added.";
export const can_mint = "Functions are available to mint tokens and increase the Total Supply in circulation (RISKY).";
export const not_can_mint = "There are no functions in the contract that allow this action by the owner.";
export const can_burn = "The contract can burn tokens, i.e., send them to the dead wallet (0x000000000000000).";
export const not_can_burn = "The contract does not allow burning tokens from the deployed Total Supply.";
export const has_trading_cooldown = "Yes, it's present in the contract, it could be used to avoid multi-transactions.";
export const not_has_trading_cooldown = "Such function is not present in the contract, preventing multiple transactions within the token.";
export const honeypot = "The token is a scam, investors cannot sell due to a function activated by the contract owner.";
export const not_honeypot = "The token is safe, investors can sell whenever they want.";
export const external_contract_risk = "There are other contracts that can manage its behavior.";
export const not_external_contract_risk = "There are no other contracts that can manage its functions.";
export const has_suspicious_functions = "Yes, it contains suspicious functions in the contract, that are ";
export const not_has_suspicious_functions = "It doesn't contain any suspicious functions.";
export const pause_trading = "The contract owner can halt trading within the token, thus preventing you from selling.";
export const not_pause_trading = "The contract owner cannot halt trading within the token, thus allowing you to sell at any time.";


// export const SERVER_URL='http://192.168.136.108:5000';
// export const SERVER_URL='http://45.61.150.65:5000';
export const SERVER_URL='https://api.mindaiproject.com';
// export const SERVER_URL='http://213.210.20.127:5000';
export const API_URL=SERVER_URL + '/api';
export const mail = "itslenson@mindaiproject.com";
export const SIGN_IN_URL = API_URL + '/user/signin';
export const SIGN_IN_WALLET_URL = API_URL + '/user/signin/wallet';
export const SIGN_UP_WALLET_URL = API_URL + '/user/signup/wallet';
export const SIGN_UP_URL = API_URL + '/user/signup';
export const VERIFY_EMAIL_URL = API_URL + '/user/verify';
export const FORGET_PASSWORD_EMAIL_URL = API_URL + '/user/forget';
export const CHANGE_PASSWORD_EMAIL_URL = API_URL + '/user/password';
export const CREATE_ASSISTANTS_URL = API_URL + '/set/prompts';

export const payment_status = ['paid', 'paid_over', 'wrong_amount', 'process', 'confirm_check', 'wrong_amount_waiting', 'check', 'fail', 'cancel', 'system_fail', 'refund_process', 'refund_fail', 'refund_paid', 'locked'];