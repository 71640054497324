import axios from 'axios';
import { API_URL } from "../util/constant"

export const getAuditRequest = (chain, tokenAddress, setResponse, errorMsg) => {
    axios.post(`${API_URL}/get/audit`, {chain, tokenAddress})
        .then(res => {
            setResponse(res.data)
        })
        .catch(err => errorMsg('Something went wrong.'));
}
